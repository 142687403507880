import React, { useEffect } from "react";
import { useAuthState } from "../../context/AuthContext";

import { Heading } from "../../components";

import { useAPICall } from "../../hooks";

import { Prism } from "@mantine/prism";

import vsLight from "prism-react-renderer/themes/vsLight";
import vsDark from "prism-react-renderer/themes/vsDark";
import { Table, Card, Title, Text, Box, Container, Skeleton } from "@mantine/core";

import checkDataType from "../../utils/checkDataType";
import { dateDiffFromToday, getFromLocalStorage } from "./../../utils/helpers";
import { getUserAttributes } from "../../api/Account";
import { useIntl } from "react-intl";

export function UserAttributes() {
  const auth = useAuthState();
  const intl = useIntl();

  const attributeCode = `<script>
  // To send user attributes place this snippet AFTER the FeedbackSpark survey code.
  spark('setAttributes', {
    '<ATTRIBUTE_NAME>': '<ATTRIBUTE_VALUE>',
    // 'email': 'john.dow@example.com', // Use email as the key for email attribute
    // 'created at': '2024-09-16T12:25:40.646Z',// Date in ISO-8601 format
    // 'Total Visits': 10, // number
    // 'AB Test Assigned': 'Version A' // string in quotes
  });
  </script>`;

  //const [attribs, setAttribs] = useState({});

  console.log("user in sites is", auth.user);
  console.log("current product in sites is", auth.currentProduct);
  const userToken = auth.currentProduct !== null ? auth.currentProduct.token : auth.organization.products[0].token;
  const environment = getFromLocalStorage("FSEnv") || "prod";

  const [attribs, getAttribs] = useAPICall(() => getUserAttributes(userToken));

  useEffect(() => {
    getAttribs();
  }, []);

  function userAttribElements(attribs) {
    console.log("Attribs from useAPICall is", attribs);

    var elements = [];
    if (attribs !== null) {
      for (const key in attribs) {
        elements.push({
          name: key,
          type: checkDataType(attribs[key]["value"]),
          received: attribs[key]["received_on"],
        });
      }
    }

    return elements;
  }

  const ths = (
    <tr>
      <th>{intl.formatMessage({ id: "user_attributes_page.attribute", defaultMessage: "Attribute" })}</th>
      <th>{intl.formatMessage({ id: "user_attributes_page.type", defaultMessage: "Type" })}</th>
      <th>{intl.formatMessage({ id: "user_attributes_page.last_received", defaultMessage: "Last Received" })}</th>
    </tr>
  );

  const rows =
    attribs.data &&
    userAttribElements(attribs.data).map(element => (
      <tr key={element.name}>
        <td>{element.name}</td>
        <td>{element.type}</td>
        <td>{dateDiffFromToday(element.received, intl.locale)}</td>
        {/* <td>{element.received}</td> */}
      </tr>
    ));

  console.log("Attribs before ", attribs);

  if (attribs && attribs.loading) {
    return <LoadingState />;
  }

  if (attribs && attribs.data && Object.keys(attribs.data).length > 0) {
    return (
      <Box>
        <Heading
          title={intl.formatMessage({ id: "user_attributes_page.heading", defaultMessage: "User Attributes" })}
          showDivider={true}
        />
        <Card shadow="sm" p="lg" radius="md" withBorder>
          <Table striped>
            <thead>{ths}</thead>
            <tbody>{rows}</tbody>
          </Table>
        </Card>
      </Box>
    );
  }

  return (
    <Box>
      <Heading
        title={intl.formatMessage({ id: "user_attributes_page.heading", defaultMessage: "User Attributes" })}
        showDivider={true}
      />
      <Container>
        <Title order={3} weight={600} align="center">
          {intl.formatMessage({
            id: "user_attributes_page.no_data_heading",
            defaultMessage: "Reach out to relevant users",
          })}
        </Title>
        <Text color="dimmed" align="center" size={16} mb={20}>
          {intl.formatMessage({
            id: "user_attributes_page.no_data_subheading",
            defaultMessage:
              "User attributes are additional data points that you already have about your users. You can send this information to FeedbackSpark to show your survey to a specific segment of users, for e.g. users who purchased in last 60 days.",
          })}
        </Text>

        {/* <Text align="center">Place this snippet AFTER the FeedbackSpark code.</Text> */}

        <Prism
          withLineNumbers
          language="javascript"
          sx={{ border: "1px dotted #ddd" }}
          getPrismTheme={(_theme, colorScheme) => (colorScheme === "light" ? vsLight : vsDark)}
        >
          {attributeCode}
        </Prism>
      </Container>
    </Box>
  );
}

const LoadingState = () => (
  <Box>
    <Skeleton height={50} mb="xl" radius="md" />
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Skeleton height={40} radius="sm" mb="md" />
      <Skeleton height={100} radius="sm" />
    </Card>
  </Box>
);
