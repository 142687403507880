import axios from "axios";
import { getFromLocalStorage } from "../utils/helpers";
import { decamelizeKeys, camelizeKeys } from "humps";

const APIBase = axios.create({
  //baseURL: 'http://staging.api.surveyhq.io/api/v1/',
  baseURL: process.env.REACT_APP_SERVER_URL + "api/v1/",
  headers: { "Content-Type": "application/json", Accept: "application/json" },
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  transformRequest: [
    function (data, headers) {
      //console.log('headers in axios create is', headers);
      // Do whatever you want to transform the data

      return decamelizeKeys(data);
    },
  ],
});
APIBase.interceptors.request.use(config => {
  const environment = getFromLocalStorage("FSEnv") || "prod";
  const currentProduct = getFromLocalStorage("currentProduct") || {};
  console.log("Current environment:", environment);
  console.log("Product from storage:", currentProduct);

  const token = currentProduct.token;
  if (token) {
    config.headers["X-FS-Token"] = token;
    console.log("Request headers:", config.headers);
  }

  // Only add env param for sandbox environment
  // if (environment === "dev") {
  //   config.params = {
  //     ...config.params,
  //     env: "dev",
  //   };
  // }

  return config;
});

export default APIBase;
