import React from "react";
import API from "../../api/API";
import { useAuthDispatch, useAuthState } from "../../context/AuthContext";
import { Navbar, NavLink, Tooltip, useMantineTheme, Button, Group, Text, createStyles, Image } from "@mantine/core";
import { IconPlus, IconStorm, IconDatabase, IconNotes, IconUsersGroup, IconPlugConnected } from "@tabler/icons-react";
import styled from "@emotion/styled";
import { IconLogout, IconSitemap, IconAd2, IconCode, IconUserCircle, IconSettings } from "@tabler/icons-react";
import { alertMessages } from "../../utils/config";
import { Link, useNavigate } from "react-router-dom";
import { showErrorNotification } from "../../components/notifications";
import { API_URLS } from "../../utils/constants";
import style from "./../../style.scss";
import { FormattedMessage, useIntl } from "react-intl";
//import LogoImage from './../../images/logo-512.png';

const useStyles = createStyles(theme => ({
  navbar: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
    top: "50px",
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    //borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
  },
}));

const NavSidebar = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const intl = useIntl();

  const dispatch = useAuthDispatch();
  let navigate = useNavigate();
  const auth = useAuthState();
  console.log("auth in NavSidebar is", auth);

  const user = auth.user;
  const userHasPermission = user.isAccountOwner || user.accessLevel == 1 || user.accessLevel == 2;

  async function handleLogout(event) {
    event.preventDefault();
    dispatch({ type: "AUTH_REQUEST" });
    let response = await API.get(API_URLS.LOGOUT_USER);
    if (response.success) {
      window.localStorage.clear();
      dispatch({ type: "LOGOUT" });
      //window.location.pathname = '/login';

      //navigate('/login', { replace: false });
    } else {
      dispatch({ type: "AUTH_ERROR", error: response.error });
    }
  }

  return (
    <>
      {/* <Navbar.Section className={classes.header} mb="lg">
        <Group spacing={5}>
          <Image
            placeholder={
              <Text fw={900} fz={32} className={style.logo}>
                FeedbackSpark
              </Text>
            }
            src="https://cdn.feedbackspark.com/logos/fslogo.svg"
            width={150}
            alt="FeedbackSpark logo"
          />
        </Group>
      </Navbar.Section> */}

      <Navbar.Section grow mx="-xs" px="xs">
        <div>
          {/* <NavLinkSHQ
            label="Dashboard"
            variant="subtle"
            component={Link}
            to="/"
            active={location.pathname === '/'}
            icon={<IconGauge size={24} stroke={1} />}
          /> */}

          <Button
            leftIcon={<IconPlus />}
            onClick={() => {
              if (userHasPermission) {
                navigate("/surveys/new");
              } else {
                showErrorNotification(
                  alertMessages.cannot_create_survey,
                  intl.formatMessage({ id: "notif.sorry", defaultMessage: "Sorry" })
                );
              }
            }}
          >
            <FormattedMessage id="nav.new_survey" />
          </Button>

          <NavLinkSHQ
            label={intl.formatMessage({ id: "nav.survey_list", defaultMessage: "Survey List" })}
            variant="subtle"
            component={Link}
            to="/surveys"
            active={location.pathname.includes("/surveys")}
            icon={<IconAd2 size={24} stroke={1} />}
          />
          {/* <Space h="md" /> */}

          {/* <NavLinkSHQ
            label="Users"
            variant="subtle"
            component={Link}
            to="/respondents"
            active={location.pathname === '/respondents'}
            icon={<IconDatabase size={24} stroke={1} />}
          /> */}
          <NavLinkSHQ
            label={intl.formatMessage({ id: "nav.user_attributes", defaultMessage: "User Attributes" })}
            variant="subtle"
            component={Link}
            to="/attributes"
            active={location.pathname === "/attributes"}
            icon={<IconDatabase size={24} stroke={1} />}
          />
          <NavLinkSHQ
            label={intl.formatMessage({ id: "nav.connect", defaultMessage: "Connect" })}
            variant="subtle"
            component={Link}
            to="/connect"
            active={location.pathname === "/connect"}
            icon={<IconCode size={24} stroke={1} />}
          />

          <NavLinkSHQ
            label={<FormattedMessage id="nav.integrations" />}
            variant="subtle"
            component={Link}
            to="/integrations"
            active={location.pathname === "/integrations"}
            icon={<IconPlugConnected size={24} stroke={1} />}
          />
        </div>
      </Navbar.Section>

      <Navbar.Section
        sx={{
          paddingTop: theme.spacing.sm,
          marginBottom: "100px",
          borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
        }}
      >
        <NavLinkSHQ
          label={intl.formatMessage({ id: "nav.account", defaultMessage: "Account" })}
          icon={<IconUserCircle size={24} stroke={1} />}
          childrenOffset={12}
          defaultOpened
        >
          <Tooltip
            label={`${intl.formatMessage({ id: "nav.sites", defaultMessage: "Sites" })}: ${
              auth.currentProduct === null ? auth.organization.products[0].name : auth.currentProduct.name
            }`}
            withArrow
            offset={-15}
          >
            <div>
              <NavLinkSHQ
                label={intl.formatMessage({ id: "nav.organization", defaultMessage: "Organization" })}
                icon={<IconSitemap size={24} stroke={1} />}
                component={Link}
                to="/account/sites"
                active={location.pathname === "/account/sites"}
              />
            </div>
          </Tooltip>
          <NavLinkSHQ
            label={intl.formatMessage({ id: "nav.team", defaultMessage: "Team" })}
            icon={<IconUsersGroup size={24} stroke={1} />}
            component={Link}
            to="/account/team"
            active={location.pathname === "/account/team"}
          />
          <NavLinkSHQ
            label={intl.formatMessage({ id: "nav.settings", defaultMessage: "Settings" })}
            icon={<IconSettings size={24} stroke={1} />}
            component={Link}
            to="/account/settings"
            active={location.pathname === "/account/settings"}
          />
          <NavLinkSHQ
            label={intl.formatMessage({ id: "nav.logout", defaultMessage: "Logout" })}
            icon={<IconLogout size={24} stroke={1} />}
            onClick={e => {
              handleLogout(e);

              //navigate('/login');
            }}
          />
        </NavLinkSHQ>
      </Navbar.Section>
    </>
  );
};
const NavLinkSHQ = styled(NavLink)`
  &.mantine-NavLink-root {
    &[data-active] {
      color: ${({ theme }) => theme.colors.Primary};
      font-weight: 700;
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
      svg {
        font-weight: 700;
      }
      .mantine-NavLink-icon {
        font-weight: 700;
      }
    }
    &:hover {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.Primary};
    }
  }
  .mantine-NavLink-description {
    display: none;
  }
  .mantine-NavLink-label {
    font-size: 1.6rem;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export default NavSidebar;
