import React from "react";
import { Title, Group, Divider, Text, Stack } from "@mantine/core";
import style from "./../style.scss";

export default function Heading({ children, title, showDivider, subheading, icon, styles }) {
  //const { title } = props;

  return (
    <div className={style.heading} style={styles}>
      <Group position="apart">
        <Stack spacing={0}>
          <Group>
            {icon}
            <Title order={1} size={22}>
              {title}
            </Title>
          </Group>

          <Text color="dimmed">{subheading}</Text>
        </Stack>

        {children}
      </Group>
      {showDivider && <Divider mt={20} mb={10} />}
    </div>
  );
}
