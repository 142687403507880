import React, { useEffect, useState } from "react";
import { Text, Group, Card, Space, Button, SimpleGrid, Anchor, ActionIcon } from "@mantine/core";
import { useForm } from "@mantine/form";
import { decamelizeKeys, camelizeKeys } from "humps";
import { useAuthState, useAuthDispatch } from "../../../../context/AuthContext";

import API from "../../../../api/API";
import { showErrorNotification, showSuccessNotification } from "../../../../components/notifications";
import style from "./../../../../style.scss";
import { uiTheme } from "../../../../theme/widgetTheme";
import widgetTheme from "../../../../theme/widgetTheme.css";

import {
  IconMoodAngry,
  IconMoodHappy,
  IconMoodEmpty,
  IconMoodSad,
  IconMoodSmile,
  IconStar,
  IconRefresh,
} from "@tabler/icons-react";
import { API_URLS } from "../../../../utils/constants";
import { ColorInputSHQ } from "../../../../components/MantineOverride";
import { set } from "lodash";

export function Appearance() {
  const { user, csrftoken, organization, currentProduct } = useAuthState();
  const [starRating, setStarRating] = useState(0);
  const [smileyRating, setSmileyRating] = useState(0);
  const [numericalRating, setNumericalRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const dispatch = useAuthDispatch();

  function handleStarRatingChange(e) {
    setStarRating(e.target.value);
  }

  function handleNumericalRatingChange(e) {
    setNumericalRating(e.target.value);
  }

  function handleEmojiRatingChange(e) {
    setSmileyRating(e.target.value);
  }

  const handleMouseEnter = value => {
    if (starRating === 0) {
      setHoverRating(value);
    }
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  function handleRatingReset() {
    setStarRating(0);
    setSmileyRating(0);
    setNumericalRating(0);
    document.querySelectorAll('input[type="radio"].emojiRadio').forEach(radio => {
      radio.checked = false;
    });
    document.querySelectorAll('input[type="radio"].ratingRadio').forEach(radio => {
      radio.checked = false;
    });
  }

  const getEmojiIcon = (value, max) => {
    const icons = {
      5: [IconMoodAngry, IconMoodSad, IconMoodEmpty, IconMoodSmile, IconMoodHappy],
    };

    const selectedIcons = icons[max] || icons[5]; // Default to 5-point scale if max is not 3 or 7
    const Icon = selectedIcons[value - 1] || IconMoodEmpty;
    return (
      <Icon
        size="100%"
        stroke="1"
        className={`${widgetTheme.emojiIcon} ${smileyRating === value ? `${widgetTheme.active}` : ""}`}
      />
    );
  };

  const ratingArray = [1, 2, 3, 4, 5];

  console.log("orgState is", organization);
  const userHasPermission = user.isAccountOwner || user.accessLevel == 1;
  const form = useForm({
    initialValues: {
      borderColor: "#d3d3d3",
      backgroundColor: "#ffffff",
      buttonColor: "#000000",
      buttonFontColor: "#ffffff",
      bodyFontColor: "#000000",
      accentColor: "#000000",
      ratingColor: "#000000",
      emojiColor: "#fbbf24",
    },
    transformValues: values => ({
      survey_settings: {
        branding: values,
      },
    }),
  });

  useEffect(() => {
    if (organization.surveySettings.branding) {
      form.setValues(organization.surveySettings.branding);
    }
  }, []);

  async function handleSubmit(values, event) {
    // console.log('Values in Apperance form submit', values);
    let response = await API.patch(
      API_URLS.UPDATE_ORGANIZATION(currentProduct.token),
      { ...decamelizeKeys(values) },
      csrftoken
    );
    //console.log(response);

    if (response.success) {
      form.resetDirty();
      showSuccessNotification("", "Looks Good!");
      console.log("UPDATE_SURVEY_OPTIONS", response.success.data);
      //dispatch({ type: 'REQUEST_SUCCESS', payload: camelizeKeys(response.success.data) });
      dispatch({ type: "UPDATE_SURVEY_OPTIONS", payload: camelizeKeys(response.success.data) });

      console.log("Auth  update success", organization);
    } else {
      showErrorNotification();
      //console.log('Product update error', response.error);
    }
    //console.log(values);
  }

  async function resetToDefaultTheme() {
    let response = await API.patch(`${API_URLS.UPDATE_ORGANIZATION(currentProduct.token)}?reset=1`, {}, csrftoken);
    console.log(response);
    if (response.success) {
      form.reset();
      dispatch({ type: "UPDATE_SURVEY_OPTIONS", payload: camelizeKeys(response.success.data) });
    } else {
      showErrorNotification();
    }
  }

  return (
    <form onSubmit={form.onSubmit((values, event) => handleSubmit(values, event))}>
      <Text size="md" fontWeight={400} mt="lg">
        Appearance
      </Text>
      <Text size="xs" color="dimmed" fontWeight={300} mb="xs">
        Change look and feel of the survey widget.
      </Text>
      <Card
        withBorder
        radius="md"
        padding="xl"
        sx={theme => ({
          backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
        })}
      >
        <SimpleGrid cols={2}>
          <div>
            <Group align="flex-end">
              <ColorInputSHQ
                disabled={!userHasPermission}
                aria-label="Background Color"
                description="Widget background color."
                defaultValue="#ffffff"
                label="Background"
                swatchesPerRow={11}
                format="hex"
                {...form.getInputProps("backgroundColor")}
              />
              <ColorInputSHQ
                //defaultValue={organization.surveySettings['branding']['buttonFontColor']}
                disabled={!userHasPermission}
                label="Font Color"
                aria-label="Font Color"
                description="Question & options font color."
                swatchesPerRow={11}
                format="hex"
                {...form.getInputProps("bodyFontColor")}
              />
            </Group>
            <Group mt={20}>
              <ColorInputSHQ
                defaultValue="#000000"
                disabled={!userHasPermission}
                label="Button"
                description="Call to action button background color."
                aria-label="Button Color"
                swatchesPerRow={11}
                format="hex"
                {...form.getInputProps("buttonColor")}
              />
              <ColorInputSHQ
                defaultValue="#ffffff"
                disabled={!userHasPermission}
                label="Button Font"
                description="Call to action button font color."
                aria-label="Button Font Color"
                swatchesPerRow={11}
                format="hex"
                {...form.getInputProps("buttonFontColor")}
              />
            </Group>
            <Group mt={20}>
              <ColorInputSHQ
                defaultValue="#000000"
                disabled={!userHasPermission}
                label="Border"
                description="Widget and input field border color."
                aria-label="Widget and input field border color."
                swatchesPerRow={11}
                format="hex"
                {...form.getInputProps("borderColor")}
              />
              <ColorInputSHQ
                defaultValue="#000000"
                disabled={!userHasPermission}
                label="Accent Color"
                description="Radio, checkbox and progress bar color."
                aria-label="Border Color"
                swatchesPerRow={11}
                format="hex"
                {...form.getInputProps("accentColor")}
              />
            </Group>
            <Group mt={20}>
              <ColorInputSHQ
                defaultValue="#000000"
                disabled={!userHasPermission}
                label="Rating Color"
                description="Rating cell background color."
                aria-label="Rating cell background color."
                swatchesPerRow={11}
                format="hex"
                {...form.getInputProps("ratingColor")}
              />
              <ColorInputSHQ
                defaultValue="#000000"
                disabled={!userHasPermission}
                label="Emoji Color"
                description="Smiley and Star background color."
                aria-label="Smiley, Star, Rating background color."
                swatchesPerRow={11}
                format="hex"
                {...form.getInputProps("emojiColor")}
              />
            </Group>
            <Group mt={20}>
              <Button disabled={form.isDirty() ? false : true} type="submit">
                Save
              </Button>
              {form.isDirty() && (
                <Button variant="outline" onClick={() => form.reset()}>
                  Cancel
                </Button>
              )}
            </Group>
            {organization.surveySettings.branding ? (
              <Anchor
                size="xs"
                color="dimmed"
                td="underline"
                component="button"
                type="button"
                onClick={() => resetToDefaultTheme()}
              >
                Reset to default
              </Anchor>
            ) : null}
          </div>
          <div>
            <Card
              withBorder
              radius="md"
              padding="xl"
              className={`${style.widetAppearanceBox} ${widgetTheme.main}`}
              style={uiTheme(form.values).widget}
              sx={{
                width: "360px",
                height: "460px",
              }}
            >
              <Text size={20} mt={10} mb={10}>
                Sample question text
              </Text>

              {/* <div
                className={`${style.checkboxWrapper} ${style.inputWrapper} ${widgetTheme.inputWrapper}`}
                style={uiTheme(form.values).inputWrapper}
              >
                <input
                  className={`${style.checkboxInput} ${widgetTheme.checkboxInput}`}
                  type="radio"
                  name="options"
                  id="dummyCheckbox1"
                  value="1"
                  style={uiTheme(form.values).choiceInput}
                />
                <label htmlFor="dummyCheckbox1">Option 1</label>
              </div> */}
              <div
                className={`${style.checkboxWrapper} ${style.inputWrapper} ${widgetTheme.inputWrapper}`}
                style={uiTheme(form.values).inputWrapper}
              >
                <input
                  className={`${style.checkboxInput} ${widgetTheme.checkboxInput}`}
                  type="checkbox"
                  name="options"
                  style={uiTheme(form.values).choiceInput}
                  id="dummyCheckbox2"
                  value="2"
                />
                <label htmlFor="dummyCheckbox2">Option 1</label>
              </div>
              <div className={`${style.inputWrapper}`} style={uiTheme(form.values).inputWrapper}>
                <textarea
                  className={`${style.textareaInput} ${widgetTheme.textareaInput}`}
                  placeholder="I think.."
                  // type="text"
                  name="dummytextarea1"
                  id="dummytextarea1"
                />
              </div>
              {/* <div
                className={`${style.checkboxWrapper}  ${style.inputWrapper} ${style.ratingLabelWrapper}`}
                style={uiTheme(form.values).ratingCell}
              >
                {ratingArray.map(i => {
                  return (
                    <div key={i}>
                      <input
                        className={`${style.checkboxInput} ${widgetTheme.ratingInput} emojiRadio`}
                        type="radio"
                        hidden
                        name="emojiRating"
                        id={`emojiRating${i}`}
                        value={i}
                        onChange={e => handleEmojiRatingChange(e)}
                      />
                      <label
                        htmlFor={`emojiRating${i}`}
                        className={`${style.ratingCell} ${style.ratingCell5} ${widgetTheme.ratingCellemoji}`}
                      >
                        {getEmojiIcon(i, 5)}
                      </label>
                    </div>
                  );
                })}
              </div> */}
              <div
                className={`${style.checkboxWrapper}  ${style.inputWrapper} ${style.ratingLabelWrapper}`}
                style={uiTheme(form.values).ratingCell}
              >
                {ratingArray.map(i => {
                  return (
                    <div key={i}>
                      <input
                        className={`${style.checkboxInput} ${widgetTheme.ratingInput}`}
                        type="radio"
                        hidden
                        name="starRating"
                        id={`starRating${i}`}
                        value={i}
                        onChange={e => handleStarRatingChange(e)}
                      />
                      <label
                        htmlFor={`starRating${i}`}
                        className={`${style.ratingCell} ${widgetTheme.ratingCellstar}`}
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <IconStar
                          size="100%"
                          stroke="1"
                          className={`${style.starIcon} ${widgetTheme.starIcon} ${
                            i <= (hoverRating || starRating) ? `${style.active} ${widgetTheme.active}` : ""
                          }`}
                        />
                        <span>{}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
              <div
                className={`${style.checkboxWrapper}  ${style.inputWrapper} ${style.ratingLabelWrapper}`}
                style={uiTheme(form.values).ratingCell}
              >
                {ratingArray.map(i => {
                  return (
                    <div key={i}>
                      <input
                        className={`${style.checkboxInput} ${widgetTheme.ratingInput} ratingRadio`}
                        type="radio"
                        hidden
                        name="numericalRating"
                        id={`numericalRating${i}`}
                        value={i}
                        onChange={e => handleNumericalRatingChange(e)}
                      />
                      <label
                        htmlFor={`numericalRating${i}`}
                        className={`${style.ratingCell} ${widgetTheme.ratingCellnumerical} ${
                          i <= numericalRating === i ? `${style.active} ${widgetTheme.active}` : ""
                        }`}
                      >
                        <span>{i}</span>
                      </label>
                    </div>
                  );
                })}
              </div>

              <div className={style.buttonWrapper}>
                <button
                  className={`${style.submitButton} ${widgetTheme.submitButton}`}
                  style={uiTheme(form.values).submitButton}
                  type="button"
                >
                  <div className={style.buttonInner}>
                    <div className={style.buttonLabel}>Done</div>
                  </div>
                </button>
              </div>
              <footer className={style.footer}>
                <div style={{ display: "block" }} className={style.progressBarContainer}>
                  <div
                    className={`${style.progressBarWrapper} ${widgetTheme.progressBarWrapper}`}
                    style={uiTheme(form.values).progressBar}
                  >
                    <div
                      className={`${style.progressbar} ${widgetTheme.progressBar}`}
                      style={{
                        width: `${0.5 * 100}%`,
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  className={`${style.brandWrapper} ${widgetTheme.brandWrapper}`}
                  style={uiTheme(form.values).shqLink}
                >
                  <div style={{ fontSize: "14px" }}>
                    <a href="https://feedbackspark.com" target="_blank" rel="noreferrer">
                      Powered by Feedback<b>Spark</b>
                    </a>
                  </div>
                </div>
              </footer>
            </Card>
            {/* <ActionIcon onClick={handleRatingReset} color="dimmed" mt={10}>
              <IconRefresh size={24} />
            </ActionIcon> */}
          </div>
        </SimpleGrid>
      </Card>
      <Space h="md" />
    </form>
  );
}
