import { getFromLocalStorage } from "./../utils/helpers";
import update from "immutability-helper";

export const initialState = {
  user: null,
  loading: true,
  csrftoken: null,
  error: null,
  currentProduct: getFromLocalStorage("currentProduct"),
  environment: getFromLocalStorage("FSEnv") || "prod",
  organization: null,
};

export const AuthReducer = (userState, action) => {
  switch (action.type) {
    case "AUTH_REQUEST":
      return {
        ...userState,
        loading: true,
      };
    case "AUTH_CHECK":
      return {
        ...userState,

        user: {
          id: action.payload.id,
          email: action.payload.email,
          isAccountOwner: action.payload.isAccountOwner,
          accessLevel: action.payload.accountRole,
        },
        currentProduct: action.payload.organization.products[0],
        organization: {
          id: action.payload.organization.id,
          name: action.payload.organization.name,
          isSubscribed:
            action.payload.organization.currentPeriodEnds && action.payload.organization.currentPeriodEnds >= Date.now()
              ? true
              : false,
          products: action.payload.organization.products,
          hasAccessTo: action.payload.organization.features,
          maxResponseCount: action.payload.organization.maxResponseCount,
          currentPlan: action.payload.organization.plan.code,
          surveySettings: action.payload.organization.surveySettings,
        },
        ...(action.payload.organization.currentPeriodEnds &&
          action.payload.organization.currentPeriodEnds >= Date.now() && {
            subscription: {
              hasAccessTill: action.payload.organization.currentPeriodEnds,
              hasFeatures: action.payload.organization.features,
              currentPlan: action.payload.organization.plan,
              customerId: action.payload.organization.stripeCustomerId,
              subscriptionId: action.payload.organization.stripeSubscriptionId,
            },
          }),
        loading: false,
      };
    case "AUTH_SUCCESS":
      return {
        ...userState,
        csrftoken: null,
        user: {
          id: action.payload.id,
          email: action.payload.email,
          isAccountOwner: action.payload.isAccountOwner,
          accessLevel: action.payload.accountRole,
        },
        currentProduct: action.payload.organization.products[0],
        organization: {
          name: action.payload.organization.name,
          isSubscribed:
            action.payload.organization.currentPeriodEnds && action.payload.organization.currentPeriodEnds >= Date.now()
              ? true
              : false,
          products: action.payload.organization.products,
          hasAccessTo: action.payload.organization.features,
          maxResponseCount: action.payload.organization.maxResponseCount,
          currentPlan: action.payload.organization.plan.code,
          surveySettings: action.payload.organization.surveySettings,
        },
        ...(action.payload.organization.currentPeriodEnds &&
          action.payload.organization.currentPeriodEnds >= Date.now() && {
            subscription: {
              hasAccessTill: action.payload.organization.currentPeriodEnds,
              hasFeatures: action.payload.organization.features,
              currentPlan: action.payload.organization.plan,
              customerId: action.payload.organization.stripeCustomerId,
              subscriptionId: action.payload.organization.stripeSubscriptionId,
            },
          }),
        loading: false,
      };
    case "AUTH_COMPLETE":
      return {
        ...userState,
        loading: false,
      };
    case "AUTH_ERROR":
      return {
        ...userState,
        loading: false,
        error: action.error,
      };
    case "SET_CURRENT_PRODUCT":
      return {
        ...userState,
        currentProduct: action.payload,
      };
    case "SET_CSRF":
      return {
        ...userState,
        csrftoken: action.payload,
      };
    case "UPDATE_PRODUCT_LOGO":
      return update(userState, {
        currentProduct: {
          $set: action.payload,
        },
      });
    case "DELETE_PRODUCT_LOGO":
      console.log("deleting logo", action.payload);
      return {
        ...userState,
        currentProduct: action.payload,
      };

    case "UPDATE_SURVEY_OPTIONS":
      console.log("Branding update", action.payload.surveySettings.branding);
      return update(userState, {
        organization: {
          surveySettings: {
            branding: {
              $set: action.payload.surveySettings.branding,
            },
          },
        },
      });
    // case 'UPDATE_SURVEY_OPTIONS':
    //   console.log('Branding update', action.payload.surveySettings.branding);
    //   return {
    //     ...userState,
    //     organization: {
    //       surveySettings: {
    //         branding: action.payload.surveySettings.branding,
    //       },
    //     },
    //   };
    case "LOGOUT":
      return {
        ...userState,
        user: null,
        loading: false,
        csrftoken: null,
        error: null,
        currentProduct: null,
        organization: null,
      };
    case "CLEAR_ERROR":
      return {
        ...userState,
        error: null,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
