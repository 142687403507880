import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "../../context/AuthContext";
import { useAPICall } from "../../hooks";
import { getSurveyWithSummary } from "../../api/Survey";
import API from "../../api/API";
import {
  Button,
  Title,
  Box,
  Grid,
  Mark,
  Flex,
  Container,
  Text,
  Accordion,
  Group,
  Tabs,
  ThemeIcon,
  Space,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { IconPlus, IconCategoryPlus, IconCaretRightFilled } from "@tabler/icons-react";
import { API_URLS } from "../../utils/constants";
import { FullPageSpinner } from "../../components/spinners";
import SurveyListItem from "./components/SurveyListItem";
import { Heading } from "../../components";
import surveyImage from "./../../images/SurveyCreation.png";
import { showErrorNotification } from "../../components/notifications";
import { FormattedMessage, useIntl } from "react-intl";
import { CampaignModal } from "./components/CampaignModal";
import { IconUser, IconUsers } from "@tabler/icons";
import { SurveyCampaignGroup } from "./components/SurveyCampaignGroup";
import { setToLocalStorage } from "../../utils/helpers";

export default function SurveyList() {
  const auth = useAuthState();
  const intl = useIntl();
  const csrfToken = auth.csrftoken;
  const currentUser = auth.user;
  const currentProduct = auth.currentProduct;
  const token = currentProduct ? currentProduct.token : auth.organization.products[0].token;
  const userHasPermission = currentUser.isAccountOwner || currentUser.accessLevel == 1 || currentUser.accessLevel == 2;

  //states
  const [surveys, setSurveys] = useState({
    user: { campaign: [], nonCampaign: [] },
    team: { campaign: [], nonCampaign: [] },
  });
  const [isLoading, setIsLoading] = useState(true);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const navigate = useNavigate();
  //const surveyTabContext = useOutletContext();
  const { activeTab } = useParams();

  //console.log("surveyTabContext", surveyTabContext);

  //API Calls------------
  async function getSurveyCampaignList(token) {
    let response = await API.get(API_URLS.GET_SURVEYCAMPAIGN, { t: token });
    if (response.success) {
      console.log("response.data in getSurveyCampaignList", response.success.data);
      return response.success.data;
    } else {
      showErrorNotification(response.error.message);
    }
  }
  const [surveyList, getSurveyList] = useAPICall(() => getSurveyWithSummary(token));
  const [surveyCampaignList, getSurveyCampaigns] = useAPICall(() => getSurveyCampaignList(token));

  //function definitions end

  //------------Effects start------------

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        // Execute both API calls concurrently
        await Promise.all([getSurveyList(), getSurveyCampaigns()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const isLoadingData = surveyList.loading || surveyCampaignList.loading;
    setIsLoading(isLoadingData);

    fetchAllData();
  }, [refresh]);

  useEffect(() => {
    if (surveyList.data && surveyCampaignList.data) {
      console.log("[surveyList] Initial campaign data:", surveyCampaignList.data);
      console.log("[surveyList] Initial survey data:", surveyList.data);

      // First create enriched campaign objects with their surveys
      const campaignsWithSurveys = surveyCampaignList.data.map(campaign => {
        const campaignSurveys = campaign.surveys
          .map(surveyId => surveyList.data.find(survey => survey.id === surveyId))
          .filter(Boolean); // Remove any undefined values

        return {
          id: campaign.id_uuid,
          name: campaign.name,
          surveys: campaignSurveys,
        };
      });

      console.log("[surveyList] Campaigns with surveys:", campaignsWithSurveys);

      // Now categorize between user and team
      const categorizedSurveys = surveyList.data.reduce(
        (acc, survey) => {
          const category = survey.user_id === auth.user.id ? "user" : "team";

          // Check if survey belongs to any campaign
          const belongsToCampaign = surveyCampaignList.data.some(campaign => campaign.surveys.includes(survey.id));

          if (!belongsToCampaign) {
            acc[category].nonCampaign.push(survey);
          }

          return acc;
        },
        {
          user: {
            campaign: campaignsWithSurveys.filter(campaign =>
              campaign.surveys.some(survey => survey.user_id === auth.user.id)
            ),
            nonCampaign: [],
          },
          team: {
            campaign: campaignsWithSurveys.filter(campaign =>
              campaign.surveys.some(survey => survey.user_id !== auth.user.id)
            ),
            nonCampaign: [],
          },
        }
      );

      console.log("[surveyList] Final categorized surveys:", categorizedSurveys);
      setSurveys(categorizedSurveys);
      setIsLoading(false);
    }
  }, [surveyList.data, surveyCampaignList.data, auth.user.id]);

  console.log("[surveyList]", surveys);

  const userSurveyCount =
    surveys.user &&
    surveys.user.campaign.reduce((total, campaign) => total + campaign.surveys.length, 0) +
      surveys.user.nonCampaign.length;

  // Total count of team surveys
  const teamSurveyCount =
    surveys.team &&
    surveys.team.campaign.reduce((total, campaign) => total + campaign.surveys.length, 0) +
      surveys.team.nonCampaign.length;

  //------------Effects end------------

  async function handleDelete(e, id) {
    e.preventDefault();
    console.log("CSRF Token in SurveyList is", csrfToken);
    let response = await API.delete(API_URLS.UPDATE_SURVEY(id), csrfToken);
    if (response.success) {
      console.log("deleted");
      setRefresh(prev => prev + 1);
    } else {
      showErrorNotification(response.error.message);
    }
  }

  function handleTabChange(value) {
    console.log("handleTabChange", value);
    setToLocalStorage("surveyActiveTab", value, "session");
    navigate(`/surveys/${value}`);
  }

  if (isLoading) {
    return <FullPageSpinner />;
  } else if (surveyList.error || surveyCampaignList.error) {
    return (
      <div>
        <FormattedMessage id="common.loading_error" />
      </div>
    );
  }

  if (surveys && surveys.length === 0) {
    return (
      <Container mt={60}>
        <Flex mih={50} gap="0" justify="center" align="center" direction="column" wrap="nowrap">
          <Title sx={{ margin: 0 }}>
            <FormattedMessage
              id="survey_list_page.no_content_heading"
              values={{
                mark: chunks => <Mark sx={{ borderRadius: "2px", paddingBottom: "2px" }}>&nbsp;{chunks}&nbsp;</Mark>,
              }}
            />
          </Title>
          <Text>{intl.formatMessage({ id: "survey_list_page.no_content_subheading" })}</Text>
          <Link to="/surveys/new">
            <Button mt={30} leftIcon={<IconPlus />}>
              <FormattedMessage id="survey_list_page.no_content_cta" />
            </Button>
          </Link>
          <Box mt={30}>
            <img
              alt={intl.formatMessage({ id: "survey_list_page.no_content_hero_alt" })}
              style={{ height: "300px", border: "6px solid #CDE6F5", borderRadius: "4px" }}
              src={surveyImage}
            />
          </Box>
        </Flex>
      </Container>
    );
  } else {
    return (
      <div>
        {/* {currentProduct.lastProdInitAt ? null : <InstallCodeBanner token={currentProduct.token} />} */}
        <Group position="apart" mb={20}>
          <Heading title={intl.formatMessage({ id: "survey_list_page.heading" })} styles={{ margin: 0 }} />

          {userHasPermission && (
            <>
              {/* <TextLink text="Create Campaign" handleClick={() => setCreateModalOpen(true)} /> */}
              <Group spacing={0} mr={20} align="flex-end">
                <Button
                  variant="subtle"
                  color="dark"
                  onClick={() => setCreateModalOpen(true)}
                  leftIcon={<IconCategoryPlus size={18} />}
                  mr={20}
                  size="sm"
                  styles={theme => ({
                    root: {
                      "&:hover": {
                        //color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.indigo[7],
                        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[1],
                      },
                      margin: "0 !important",
                    },
                  })}
                >
                  Create Campaign
                </Button>
              </Group>
            </>
          )}

          <CampaignModal
            surveyList={surveyList.data}
            campaignModalOpened={createModalOpen}
            setCampaignModalOpened={setCreateModalOpen}
            setRefresh={setRefresh}
            isEditing={false}
          />
        </Group>

        <Tabs
          //defaultValue={surveyTabContext}
          keepMounted={false}
          value={activeTab}
          onTabChange={value => handleTabChange(value)}
        >
          <Tabs.List>
            <Tabs.Tab value="my-research" icon={<IconUser size={16} />}>
              <Text size={16}>
                Your Research{" "}
                <ThemeIcon variant="light" radius="xl" color={activeTab !== "my-research" && "gray"}>
                  {userSurveyCount}
                </ThemeIcon>
              </Text>
            </Tabs.Tab>
            <Tabs.Tab value="team-research" icon={<IconUsers size={16} />}>
              <Text size={16}>
                Team Research
                <ThemeIcon variant="light" radius="xl" color={activeTab !== "team-research" && "gray"}>
                  {teamSurveyCount}
                </ThemeIcon>
              </Text>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="my-research" pt="xs">
            {surveys.user.campaign.length > 0 && (
              <Accordion
                chevronPosition="left"
                variant="filled"
                chevron={<IconCaretRightFilled size={16} />}
                defaultValue="campaign"
                styles={{
                  chevron: {
                    // styles added to chevron when it should rotate
                    "&[data-rotate]": {
                      transform: "rotate(90deg)",
                    },
                  },
                }}
              >
                <Accordion.Item value="campaign">
                  <Accordion.Control>
                    <Text weight={700}>Campaigns</Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <SurveyCampaignGroup
                      campaigns={surveys.user.campaign}
                      handleDelete={handleDelete}
                      currentUser={currentUser}
                      csrfToken={csrfToken}
                      setRefresh={setRefresh}
                      userHasPermission={userHasPermission}
                      campaignModalOpened={editModalOpen}
                      setCampaignModalOpened={setEditModalOpen}
                      surveyList={surveyList.data}
                      intl={intl}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            )}
            {surveys.user.nonCampaign.length > 0 && surveys.user.campaign.length > 0 && (
              <Accordion
                variant="filled"
                chevronPosition="left"
                defaultValue="noncampaign"
                chevron={<IconCaretRightFilled size={16} />}
                styles={{
                  chevron: {
                    // styles added to chevron when it should rotate
                    "&[data-rotate]": {
                      transform: "rotate(90deg)",
                    },
                  },
                }}
              >
                <Accordion.Item value="noncampaign">
                  <Accordion.Control>
                    <Text weight={700}>Standalone Surveys</Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <SurveyGrid
                      surveys={surveys.user.nonCampaign}
                      handleDelete={handleDelete}
                      currentUser={currentUser}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            )}
            {surveys.user.nonCampaign.length > 0 && surveys.user.campaign.length == 0 && (
              <>
                <Space mb={20} />

                <SurveyGrid surveys={surveys.user.nonCampaign} handleDelete={handleDelete} currentUser={currentUser} />
              </>
            )}
            {!surveys.user.campaign.length && !surveys.user.nonCampaign.length && <EmptyStateView />}
          </Tabs.Panel>

          <Tabs.Panel value="team-research" pt="xs">
            {surveys.team.campaign.length > 0 && (
              <Accordion
                chevronPosition="left"
                variant="filled"
                chevron={<IconCaretRightFilled size={16} />}
                defaultValue="team_campaign"
                styles={{
                  chevron: {
                    // styles added to chevron when it should rotate
                    "&[data-rotate]": {
                      transform: "rotate(90deg)",
                    },
                  },
                }}
              >
                <Accordion.Item value="team_campaign">
                  <Accordion.Control>
                    <Text weight={700}>Campaigns</Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <SurveyCampaignGroup
                      userHasPermission={userHasPermission}
                      campaigns={surveys.team.campaign}
                      handleDelete={handleDelete}
                      currentUser={currentUser}
                      setRefresh={setRefresh}
                      csrfToken={csrfToken}
                      campaignModalOpened={editModalOpen}
                      setCampaignModalOpened={setEditModalOpen}
                      surveyList={surveyList.data}
                      intl={intl}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            )}
            {surveys.team.nonCampaign.length > 0 && surveys.team.campaign.length > 0 && (
              <Accordion
                variant="filled"
                chevronPosition="left"
                defaultValue="team_noncampaign"
                chevron={<IconCaretRightFilled size={16} />}
                styles={{
                  chevron: {
                    // styles added to chevron when it should rotate
                    "&[data-rotate]": {
                      transform: "rotate(90deg)",
                    },
                  },
                }}
              >
                <Accordion.Item value="team_noncampaign">
                  <Accordion.Control>
                    <Text weight={700}>Standalone Surveys</Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <SurveyGrid
                      surveys={surveys.team.nonCampaign}
                      handleDelete={handleDelete}
                      currentUser={currentUser}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            )}
            {surveys.team.nonCampaign.length > 0 && surveys.team.campaign.length == 0 && (
              <>
                <Space mb={20} />

                <SurveyGrid surveys={surveys.team.nonCampaign} handleDelete={handleDelete} currentUser={currentUser} />
              </>
            )}

            {!surveys.team.campaign.length && !surveys.team.nonCampaign.length && <EmptyStateView />}
          </Tabs.Panel>
        </Tabs>
      </div>
    );
  }
}

const SurveyGrid = ({ surveys, handleDelete, currentUser }) => {
  if (!surveys?.length) {
    return <EmptyStateView />;
  }

  const availableSurveys = surveys.filter(survey => !survey.campaign);

  return (
    <Grid>
      {availableSurveys.map(survey => (
        <Grid.Col key={survey.id} md={6} lg={3} sx={{ position: "relative" }}>
          <SurveyListItem survey={survey} handleDelete={handleDelete} currentUser={currentUser} />
        </Grid.Col>
      ))}
    </Grid>
  );
};

const EmptyStateView = () => {
  return (
    <Box>
      <Text italic size={14} color="dimmed">
        No surveys available.
      </Text>
    </Box>
  );
};
