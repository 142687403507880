import React, { useEffect, useState } from "react";
import { showErrorNotification, showSuccessNotification } from "../../../components/notifications";
import API from "../../../api/API";
import { API_URLS } from "../../../utils/constants";
import { Box, Grid, Text, Group, ActionIcon, Menu } from "@mantine/core";
import { IconDotsVertical, IconPencil, IconTrash } from "@tabler/icons-react";
import SurveyListItem from "./SurveyListItem";
import { CampaignModal } from "./CampaignModal";

export const SurveyCampaignGroup = ({
  userHasPermission,
  campaigns,
  handleDelete,
  currentUser,
  csrfToken,
  setRefresh,
  intl,
  campaignModalOpened,
  setCampaignModalOpened,
  surveyList,
  hideDelete,
}) => {
  console.log("[surveyList] campaigns", campaigns);

  function handleCampaignEditing(e) {
    e.preventDefault();
    setCampaignModalOpened(true);
  }
  async function handleDeleteCampaign(e, id) {
    e.preventDefault();
    console.log("CSRF Token in SurveyList is", csrfToken);
    let response = await API.delete(API_URLS.UPDATE_CAMPAIGN(id), csrfToken);
    if (response.success) {
      console.log("deleted");
      showSuccessNotification("Campaign deleted successfully");
      setRefresh(prev => prev + 1); // Trigger refetch
      return;
    } else {
      showErrorNotification(response.error.message);
      return;
    }
  }

  return (
    <Box>
      {campaigns.map(campaign => (
        <Box
          key={campaign.id}
          mb="md"
          sx={theme => ({
            //backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1],
            //padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
            border: "1px solid #CED4DA",
            cursor: "pointer",
          })}
        >
          <Group sx={{ padding: "10px 20px" }} position="apart">
            <Text size={16} weight={700}>
              {campaign.name}
            </Text>
            <Menu withinPortal position="bottom-end" shadow="sm">
              <Menu.Target>
                <ActionIcon>
                  <IconDotsVertical size={16} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconPencil size={14} />}
                  onClick={e => handleCampaignEditing(e, campaign.id)}
                  sx={!userHasPermission && { pointerEvents: "none", color: "#adb5bd" }}
                >
                  {intl.formatMessage({ id: "common.edit" })}
                </Menu.Item>
                <Menu.Item
                  color="red"
                  icon={<IconTrash size={14} />}
                  onClick={e => handleDeleteCampaign(e, campaign.id)}
                  disabled={!userHasPermission || hideDelete}
                >
                  {intl.formatMessage({ id: "common.delete" })}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>

          <Grid sx={{ padding: "0px 20px" }}>
            {campaign.surveys.map(survey => (
              <Grid.Col key={survey.id} md={6} lg={3} sx={{ position: "relative" }}>
                <SurveyListItem
                  survey={survey}
                  handleDelete={handleDelete}
                  currentUser={currentUser}
                  hideDelete={campaign.surveys.length < 3 ? true : false}
                />
              </Grid.Col>
            ))}
          </Grid>
          <CampaignModal
            campaign={campaign}
            surveyList={surveyList}
            campaignModalOpened={campaignModalOpened}
            setCampaignModalOpened={setCampaignModalOpened}
            setRefresh={setRefresh}
            isEditing={true}
          />
        </Box>
      ))}
    </Box>
  );
};
