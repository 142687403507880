import React, { useEffect, useState } from "react";
import { Box, Image, Center, Loader, Group } from "@mantine/core";
import { Heading } from "../../components";
import ZapierLogo from "../../images/zapierLogo.png";
import { useIntl } from "react-intl";
import { getAPIKey } from "../../api/Account";
import { useAuthState } from "../../context/AuthContext";
import APIKeyBox from "../../components/APIKeyBox";

export default function Zapier() {
  const intl = useIntl();
  const auth = useAuthState();
  const [APIKey, setAPIKey] = useState(null);
  const [loading, setLoading] = useState(false);

  const token = auth.currentProduct.token;

  useEffect(() => {
    setLoading(true);
    getAPIKey(token, "production").then(data => {
      console.log("APIKey data", data);
      if (data.status === 200) {
        setAPIKey(data.data.key);
      }
      setLoading(false);
    });
  }, []);

  return (
    <Box>
      <Heading
        title="Zapier"
        icon={<Image src={ZapierLogo} width={50} height={50} alt="Zapier Logo" radius="md" />}
        showDivider={true}
        subheading={intl.formatMessage({
          id: "integrations.zapier_subheading",
          defaultMessage: "Send survey data to 7000+ apps.",
        })}
      />
      {loading ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <Group>
          <APIKeyBox apiKey={APIKey} boxWidth="550px" />
        </Group>
      )}
    </Box>
  );
}
