import React, { useState } from "react";
import { useAuthState } from "../../context/AuthContext";
import { Respondents } from "./Respondents";
import { UserAttributes } from "./UserAttributes";

import { Tabs } from "@mantine/core";

function Data() {
  const auth = useAuthState();
  const [activeTab, setActiveTab] = useState("respondents");

  //const [attribs, setAttribs] = useState({});

  console.log("user in sites is", auth.user);
  console.log("current product in sites is", auth.currentProduct);

  return (
    <Tabs value={activeTab} onTabChange={setActiveTab}>
      <Tabs.List>
        <Tabs.Tab value="respondents">Users</Tabs.Tab>
        <Tabs.Tab value="attributes">Attributes</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="respondents">
        <Respondents />
      </Tabs.Panel>
      <Tabs.Panel value="attributes">
        <UserAttributes />
      </Tabs.Panel>
    </Tabs>
  );
}

export default Data;
