import React from "react";
import { IconWorld, IconLink, IconMail } from "@tabler/icons-react";

export const badgeColor = state => {
  switch (state) {
    case 0:
      return "yellow";
    case 1:
      return "green";
    case 2:
      return "red";
    case 3:
      return "gray";
    case 4:
      return "gray";
    default:
      break;
  }
};

export function translatedSurveyStates(intl, state) {
  switch (state) {
    case 0:
      return intl.formatMessage({ id: "survey.in_draft" });
    case 1:
      return intl.formatMessage({ id: "survey.live" });
    case 2:
      return intl.formatMessage({ id: "survey.paused" });
    case 3:
      return intl.formatMessage({ id: "survey.completed" });
    case 4:
      return intl.formatMessage({ id: "survey.archived" });
    default:
      break;
  }
}

export function surveyPlatformIcon(platform) {
  switch (platform) {
    case "email":
      return <IconMail />;
    case "link":
      return <IconLink />;
    case "web":
      return <IconWorld />;
    default:
      break;
  }
}
