import React, { useEffect } from "react";
//import Select from 'react-select';
import { Radio, Group, Text, Space, Divider, Stack, Card } from "@mantine/core";

import { capitalize, getPlatformIcon } from "../../../../utils/helpers";
import { useIntl } from "react-intl";

import { SubHeadingText } from "../../../../components/MantineOverride";
// import { wrap } from '@sentry/react';

export function SurveyPlatform(props) {
  const { dispatch, formState } = props;
  const intl = useIntl();

  // let options = formState.surveyOptions;
  // function handleInputChange(value, section, field) {
  //   console.log("$123 value in radio group input is", value);
  //   dispatch({
  //     type: "settingChange",
  //     value: value,
  //     section: section,
  //     field: field,
  //   });
  // }

  const defaultPlatforms = [
    {
      name: intl.formatMessage({ id: "survey.platform_website", defaultMessage: "Website" }),
      code: "web",
      description: intl.formatMessage({
        id: "survey.platform_website_desc",
        defaultMessage: "Show survey inside your website.",
      }),
    },
    {
      name: intl.formatMessage({ id: "survey.platform_link", defaultMessage: "Link" }),
      code: "link",
      description: intl.formatMessage({
        id: "survey.platform_link_desc",
        defaultMessage: "Share unique survey link with your users.",
      }),
    },
    {
      name: intl.formatMessage({ id: "survey.platform_email", defaultMessage: "Email" }),
      code: "email",
      description: intl.formatMessage({
        id: "survey.platform_email_desc",
        defaultMessage: "Embed survey in an email.",
      }),
    },
  ];

  useEffect(() => {
    //this is effect is to synchronise formState update
    //Ref: https://samuelsson.dev/update-state-with-usereducer-synchronously/
  }, [formState]);

  function handlePlatformChange(value) {
    dispatch({
      type: "updatePlatform",
      value: value,
    });
  }

  // const selectedPlatforms = () => {
  //   return capitalize(formState.platform);
  // };

  return (
    <>
      <Space h="md" />
      <Card sx={{ overflow: "visible" }} shadow="sm" p="lg" radius="md" mb="xs" withBorder>
        <SubHeadingText>
          {intl.formatMessage({
            id: "survey.select_delivery_platform",
            defaultMessage: "Select survey delivery platform.",
          })}
        </SubHeadingText>
        <Space h="xs" />
        <Divider></Divider>

        <Space h="xs" />
        <Radio.Group
          orientation="vertical"
          value={formState.platform}
          name="selectPlatform"
          onChange={e => handlePlatformChange(e)}
        >
          {defaultPlatforms.map(platform => (
            <Group
              key={platform.code}
              spacing="12px"
              sx={{ flexWrap: "wrap", justifyContent: "flex-start", marginBottom: "20px" }}
            >
              <Radio value={platform.code} />
              <Group spacing="10px" position="right">
                {getPlatformIcon(platform.code)}
                <Stack spacing={4}>
                  <Text size="sm" sx={{ lineHeight: 1 }}>
                    {capitalize(platform.name)}
                  </Text>
                  <Text size="xs" color="dimmed" sx={{ lineHeight: 1 }}>
                    {capitalize(platform.description)}
                  </Text>
                </Stack>
              </Group>
            </Group>
          ))}
        </Radio.Group>
      </Card>
      <Space h="md" />
      {/* {formState.platform === "email" && (
        <Card sx={{ overflow: "visible" }}>
          <StyledSelect
            // dropdownPosition="bottom"
            value={options.email?.platform}
            onChange={val => handleInputChange(val, "email", "platform")}
            label="Email Delivery Platform"
            placeholder="Pick one"
            data={[
              { value: "zendesk", label: "Zendesk", icon: "" },
              { value: "sendgrid", label: "Sendgrid", icon: "" },
              { value: "mailchimp", label: "Mailchimp", icon: "" },
              { value: "mailgun", label: "Mailgun", icon: "" },
            ]}
          />
          <Text size={14} color="dimmed" ml=".5rem">
            {options.email?.platform ? (
              <FormattedMessage
                id="survey.delivery_platform_tip"
                values={{
                  platform_tag: platformEmailTag(options.email?.platform),
                }}
                defaultMessage={`${platformEmailTag(
                  options.email?.platform
                )} tag will be used to parse respondent email.`}
              />
            ) : (
              ""
            )}
          </Text>
        </Card>
      )} */}

      <Space h="md" />
    </>
  );
}
