import React, { useState } from "react";
import { Menu, Button, Text, Group } from "@mantine/core";
import { IconChevronDown, IconRocket, IconFlask } from "@tabler/icons-react";
import { getFromLocalStorage, setToLocalStorage } from "../utils/helpers";

const EnvironmentSwitcher = ({ className }) => {
  const environments = [
    {
      value: "prod",
      label: "Production",
      icon: IconRocket,
      description: "Live environment for real users.",
      indicatorColor: "green",
    },
    {
      value: "dev",
      label: "Sandbox",
      icon: IconFlask,
      description: "Test environment for development.",
      indicatorColor: "#ea580c",
    },
  ];

  const [currentEnvironment, setCurrentEnvironment] = useState(getFromLocalStorage("FSEnv") || "prod");

  const handleEnvironmentChange = newEnvironment => {
    setCurrentEnvironment(newEnvironment);
    setToLocalStorage("FSEnv", newEnvironment, "local");
    window.location.reload(false);

    // Here you would typically:
    // 1. Update any environment-specific state
    // 2. Fetch surveys for the new environment
    // 3. Update the URL if you're using environment in the route
  };

  const currentEnv = environments.find(env => env.value === currentEnvironment);

  return (
    <Menu shadow="md" width={300}>
      <Menu.Target>
        <Button variant="white" color="dark" rightIcon={<IconChevronDown size={16} />} className={className} compact>
          <Group spacing="xs">
            <div
              style={{
                width: 8,
                height: 8,
                borderRadius: "50%",
                backgroundColor: currentEnv.indicatorColor,
              }}
            />
            <Text size={14}>{currentEnv.label}</Text>
          </Group>
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Switch Environment</Menu.Label>
        {environments.map(env => (
          <Menu.Item
            key={env.value}
            icon={<env.icon size={16} />}
            onClick={() => handleEnvironmentChange(env.value)}
            style={{
              backgroundColor: currentEnvironment === env.value ? "var(--mantine-color-blue-0)" : undefined,
            }}
          >
            <Group position="apart" align="center">
              <div>
                <Text size="sm" weight={500}>
                  {env.label}
                </Text>
                <Text size="xs" color="dimmed">
                  {env.description}
                </Text>
              </div>
              {currentEnvironment === env.value && (
                <div
                  style={{
                    width: 8,
                    height: 8,
                    borderRadius: "50%",
                    backgroundColor: env.indicatorColor,
                  }}
                />
              )}
            </Group>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default EnvironmentSwitcher;
