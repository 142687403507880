import React, { useEffect } from "react";
import { decamelizeKeys } from "humps";
import { Text, Group, Card, NumberInput, Button } from "@mantine/core";
import { isInRange, useForm } from "@mantine/form";
import { useAuthState } from "../../../../context/AuthContext";
import API from "../../../../api/API";
import { API_URLS } from "../../../../utils/constants";
import { showErrorNotification, showSuccessNotification } from "../../../../components/notifications";
import { NumberInputSHQ } from "../../../../components/MantineOverride";

export function Recontact() {
  const { user, csrftoken, organization, currentProduct } = useAuthState();
  //console.log('currentProduct: ', currentProduct);
  const userHasPermission = user.isAccountOwner || user.accessLevel == 1;
  const form = useForm({
    initialValues: {
      minGap: organization.surveySettings.reconnect?.minGap || 7,
    },
    validateInputOnChange: true,
    validate: {
      minGap: isInRange({ min: 1 }, "This value cannot be less than 1 day"),
    },
    transformValues: values => ({
      survey_settings: {
        reconnect: values,
      },
    }),
  });

  async function handleSubmit(values, event) {
    //console.log('Values in Apperance form submit', decamelizeKeys(values));
    let response = await API.patch(
      API_URLS.UPDATE_ORGANIZATION(currentProduct.token),
      { ...decamelizeKeys(values) },
      csrftoken
    );
    console.log(response);

    if (response.success) {
      form.resetDirty();
      showSuccessNotification("Reconnect period updated");
    } else {
      showErrorNotification();
    }
    console.log(values);
  }

  return (
    <form onSubmit={form.onSubmit((values, event) => handleSubmit(values, event))}>
      <Text size="md" fontWeight={400}>
        Reconnect period
      </Text>
      <Text size="xs" color="dimmed" fontWeight={400} mb="xs">
        Minimum number of days before same user is shown their next survey. Defaults to 7 days.
      </Text>

      <Card
        withBorder
        radius="md"
        padding="xl"
        sx={theme => ({
          backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
        })}
      >
        <Group align="flex-start">
          <NumberInputSHQ {...form.getInputProps("minGap")} min={1} disabled={!userHasPermission} />

          <Button disabled={form.isDirty() && form.isValid() ? false : true} type="submit">
            Save
          </Button>
          {form.isDirty() && (
            <Button variant="outline" onClick={() => form.reset()}>
              Cancel
            </Button>
          )}
        </Group>
      </Card>
    </form>
  );
}
!(function () {
  try {
    var e =
        "undefined" != typeof window
          ? window
          : "undefined" != typeof global
          ? global
          : "undefined" != typeof self
          ? self
          : {},
      n = new Error().stack;
    n &&
      ((e._sentryDebugIds = e._sentryDebugIds || {}), (e._sentryDebugIds[n] = "942754d9-4766-5331-98de-0869dc990368"));
  } catch (e) {}
})();
//# debugId=942754d9-4766-5331-98de-0869dc990368
