import React, { useEffect, useState } from "react";
import style from "../../../style.scss";
import { surveyLinkURL } from "../../../utils/helpers";
import { embedEmailContent } from "../../../utils/embedEmailContent";
import { platformEmailTag } from "../../../utils/config";

export function PreviewSurveyEmail(props) {
  const { survey, token } = props;

  // const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  // const [foreGroundColor, setForeGroundColor] = useState("#ffffff");
  // const [questionColor, setQuestionColor] = useState("#000000");
  // const [optionFontColor, setOptionFontColor] = useState("#333333");
  // const [optionBorderColor, setOptionBorderColor] = useState("#cccccc");
  const [emailStyle, setEmailStyle] = useState({
    backgroundColor: "#ffffff",
    foreGroundColor: "#ffffff",
    questionFontSize: "18px",
    questionColor: "#000000",
    optionFontColor: "#333333",
    optionFontSize: "16px",
    optionBorderColor: "#cccccc",
  });

  // const [emailContent, setEmailContent] = useState(
  //   embedEmailContent(firstQuestion, surveyEndpoint, emailTag, emailStyle)
  // );

  console.log("! emailStyle", emailStyle);

  const firstQuestion = survey.questions[0];
  const surveyEndpoint = surveyLinkURL(token, survey.code);
  const emailTag = platformEmailTag(survey.survey_options.email?.platform);

  let emailContent = embedEmailContent(firstQuestion, surveyEndpoint, emailTag, emailStyle);

  useEffect(() => {
    emailContent = embedEmailContent(firstQuestion, surveyEndpoint, emailTag, emailStyle);
  }, [emailStyle]);

  return (
    <div style={{ marginTop: "50px" }}>
      <div className={`${style.browserMockup} ${style.emailMockup}`}>
        <div className={style.content}>
          <div className={`${style.line} ${style.lineShort}`}></div>
          <div className={`${style.line} ${style.lineMedium}`}></div>
          <div className={`${style.line} ${style.lineLong}`}></div>
          <div
            style={{
              margin: "0 auto",
              //border: "1px solid #ddd",
              padding: "20px 10px 30px 10px",
              marginTop: "30px",
            }}
            dangerouslySetInnerHTML={{
              __html: emailContent,
            }}
          />
          <div className={`${style.line} ${style.lineLong}`}></div>
          <div className={`${style.line} ${style.lineMedium}`}></div>
          <div className={`${style.line} ${style.lineShort}`}></div>
        </div>
      </div>
    </div>
  );
}
