import React, { useEffect, useState } from "react";
import { Heading } from "../../components";
import { Box, Button, Text, Space, Group, Stack, Tooltip, Table, Tabs, Center, Loader, Image } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DeleteAlertModal } from "../../components/UI";
import WebhookLogs from "./components/webhooks/WebhookLogs";
import WebhookInfo from "./components/webhooks/WebhookInfo";
import { useAuthState } from "../../context/AuthContext";

import API from "../../api/API";
import { useIntl } from "react-intl";
import { eventHumanString } from "../../utils/helpers";
import webhookIcon from "../../images/webhook.png";
import APIKeyBox from "../../components/APIKeyBox";

export default function Webhook() {
  const auth = useAuthState();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [webhookId, setWebhookId] = useState(null);
  const [signingSecret, setSigningSecret] = useState(null);
  const [opened, { close, open }] = useDisclosure(false);
  const [webhookList, setWebhookList] = useState([]);
  const [webhookLogs, setWebhookLogs] = useState([]);

  const userHasPermission = auth.user.isAccountOwner || auth.user.accessLevel == 1;

  console.log("auth", auth);

  function createWebhookConfig() {
    API.post("integrations/", { integration_type: "webhook" }, auth.csrftoken)
      .then(data => {
        console.log("webhook data create", data);

        if (data.success && data.success.status === 201) {
          setSigningSecret(data.success.data.token);
          setWebhookId(data.success.data.id);
        }
      })
      .catch(err => {
        console.log("error", err);
      });
  }

  function getWebhookConfig() {
    API.get("integrations/?type=webhook")
      .then(data => {
        console.log("webhook data get", data);
        if (data.success) {
          setLoading(false);
          setSigningSecret(data.success.data.token);
          setWebhookId(data.success.data.id);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function getWebhooks() {
    let response = await API.get(`integrations/webhooks/`);
    if (response.success) {
      setWebhookList(response.success.data);
    }
  }

  const webhookTableData =
    webhookList &&
    webhookList.map(webhook => {
      return {
        id: webhook.id,
        survey_name: webhook.survey_name,
        name: webhook.name,
        endpoint: webhook.settings.webhook_endpoint,
        event: webhook.event,
      };
    });

  const rows =
    webhookTableData &&
    webhookTableData.map(row => (
      <tr key={row.id}>
        <td>
          <Tooltip label={row.name} position="top" withArrow>
            <Text sx={{ width: "120px" }} truncate>
              {row.survey_name}
            </Text>
          </Tooltip>
        </td>
        <td>
          <Tooltip label={row.name} position="top" withArrow>
            <Text sx={{ width: "120px" }} truncate>
              {row.name}
            </Text>
          </Tooltip>
        </td>
        <td>
          <Tooltip label={row.endpoint} position="top" withArrow>
            <Text sx={{ width: "200px" }} truncate>
              {row.endpoint}
            </Text>
          </Tooltip>
        </td>
        <td>
          <Text truncate="end">{eventHumanString(row.event)}</Text>
        </td>
      </tr>
    ));

  function handleDelete() {
    deleteWebhookConfig();

    close();
  }

  function regenerateSigningKey() {
    API.patch(`integrations/${webhookId}/`, {}, auth.csrftoken)
      .then(data => {
        console.log("webhook data regenerate", data);
        if (data.success && data.success.status === 200) {
          setSigningSecret(data.success.data.token);
        }
      })
      .catch(err => {
        console.log("error", err);
      });
  }

  function deleteWebhookConfig() {
    API.delete(`integrations/${webhookId}/`, auth.csrftoken)
      .then(data => {
        console.log("webhook data delete", data);
        if (data.success && data.success.status === 204) {
          setSigningSecret(null);
          setWebhookId(null);
          setWebhookList([]);
          setWebhookLogs([]);
        }
      })
      .catch(err => {
        console.log("error", err);
      });
  }

  useEffect(() => {
    setLoading(true);
    getWebhookConfig();
    if (webhookId) {
      getWebhooks();
    }
  }, []);

  useEffect(() => {}, [webhookId]);

  return (
    <Box>
      <Heading
        title={intl.formatMessage({
          id: "integrations.webhook_heading",
          defaultMessage: "Webhooks",
        })}
        icon={<Image src={webhookIcon} width={50} height={50} alt="Zapier Logo" radius="md" fit="contain" />}
        showDivider={true}
        subheading={intl.formatMessage({
          id: "integrations.webhook_subheading",
          defaultMessage: "Send survey data to external URLs.",
        })}
      />

      <Space h="md" />

      {userHasPermission && (
        <>
          {loading ? (
            <Center>
              <Loader size={40} />
            </Center>
          ) : (
            <Group position="apart">
              {signingSecret ? (
                <Stack spacing={0}>
                  <Text size={14} fw={700}>
                    Signing Secret
                  </Text>

                  <Group>
                    <APIKeyBox apiKey={signingSecret} boxWidth="380px" />
                    <Button onClick={regenerateSigningKey}>Regenerate</Button>
                  </Group>
                </Stack>
              ) : null}

              <Button variant="outline" onClick={webhookId ? open : createWebhookConfig}>
                {webhookId
                  ? intl.formatMessage({ id: "integrations.disconnect", defaultMessage: "Disconnect" })
                  : intl.formatMessage({ id: "integrations.connect", defaultMessage: "Connect" })}
              </Button>
            </Group>
          )}

          <Space h="50px" />
        </>
      )}

      <Tabs defaultValue={userHasPermission ? "info" : "list"} keepMounted={false}>
        <Tabs.List>
          {userHasPermission && <Tabs.Tab value="info">Overview</Tabs.Tab>}

          <Tabs.Tab value="list">Webhook List</Tabs.Tab>
          <Tabs.Tab value="logs">Logs</Tabs.Tab>
        </Tabs.List>
        {userHasPermission && (
          <Tabs.Panel value="info" pt="xs">
            <WebhookInfo webhookId={webhookId} />
          </Tabs.Panel>
        )}

        <Tabs.Panel value="list" pt="xs">
          {rows && rows.length > 0 ? (
            <Table fontSize={14}>
              <thead>
                <tr>
                  <th>Survey</th>
                  <th>Name</th>
                  <th>Endpoint</th>
                  <th>Trigger</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          ) : (
            <Box>
              <Text mt={20} size={14} color="dimmed" italic>
                There are no webhooks.
              </Text>
            </Box>
          )}
        </Tabs.Panel>

        <Tabs.Panel value="logs" pt="xs">
          <WebhookLogs webhookLogs={webhookLogs} setWebhookLogs={setWebhookLogs} />
        </Tabs.Panel>
      </Tabs>

      <DeleteAlertModal
        opened={opened}
        close={close}
        heading={intl.formatMessage({
          id: "integrations.del_webhook_integration_alert_modal_heading",
          defaultMessage: "Are you sure?",
        })}
        message={intl.formatMessage({
          id: "integrations.del_webhook_integration_alert_modal_message",
          defaultMessage: "This will delete all existing webhooks and the configuration.",
        })}
        onConfirm={handleDelete}
      />
    </Box>
  );
}
