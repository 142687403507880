import React, { useEffect } from "react";
import style from "../../../style.scss";

export function PreviewSurveyWeb(props) {
  const { survey, currentQuestion, currentProduct, platform } = props;

  console.log("#34 currentQuestion", currentQuestion);

  const customization = {
    position: function (options) {
      if (options.widget_type == "popover") {
        switch (options.widget_position) {
          case "bottom_right":
            return {
              main: {
                right: "10px",
                bottom: "10px",
                left: "unset",
              },
            };
          case "bottom_left":
            return {
              main: {
                left: "10px",
                bottom: "10px",
                right: "unset",
              },
            };
          default:
            return {
              main: {
                right: "10px",
                bottom: "10px",
                left: "unset",
              },
            };
        }
      }
    },
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleIframeRefresh();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [survey]);

  useEffect(() => {
    handleIframeRefresh();
  }, [currentQuestion]);

  // useEffect(() => {
  //   if (survey.platform === "web") {
  //     window.addEventListener("message", function (ev) {
  //       const { message, params, channel } = ev.data;
  //       if (channel !== "_gradian") return;
  //       if (message == "reloadSurvey") {
  //         handleIframeRefresh();
  //       }
  //     });
  //   }
  // }, []);

  useEffect(() => {
    const messageHandler = ev => {
      const { message, params, channel } = ev.data;
      if (channel !== "_gradian") return;
      if (message == "reloadSurvey") {
        console.log("willrefresh");
        handleIframeRefresh();
      }
    };
    if (survey.platform === "web") {
      window.addEventListener("message", messageHandler);
    }

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  useEffect(() => {
    const gradian_container_div = document.getElementById("_gradian_container");
    const container_div = document.getElementById("_gradian_inner_container");
    const main_div = document.getElementById("_gradian_main");

    if (!gradian_container_div || !container_div || !main_div) {
      return;
    }

    container_div.className = "fspark_inner_container";

    const gradian_container_style = gradian_container_div.style;
    const container_div_style = container_div.style;
    const main_div_style = main_div.style;

    // Define the handler function outside the event listener
    const handleMessage = function (ev) {
      const { message, params, channel } = ev.data;
      console.log("#432 Message received:", ev.data);
      if (channel !== "_gradian") return;

      if (message == "closeSurvey") {
        gradian_container_style.display = "none";
      }

      if (message == "surveyPosition") {
        if (params.widget_type == "modal" || params.widget_type == "full_screen") {
          gradian_container_style.bottom = 0;
          gradian_container_style.right = 0;
          gradian_container_style.left = 0;
          gradian_container_style.top = 0;
          gradian_container_style.width = "100%";
          gradian_container_style.height = "100%";

          gradian_container_style.background = "rgb(0,0,0,0.8)";

          container_div_style.display = "flex";
          container_div_style.alignItems = "center";
          container_div_style.justifyContent = "center";
          container_div_style.padding = "1rem";

          main_div_style.position = "relative";
          main_div_style.overflow = "auto";
          main_div_style.width = "400px";
          main_div_style.height = "540px";
          main_div_style.opacity = 1;
          main_div_style.cursor = "default";
        } else {
          var styleoptions = customization.position(params);
          console.log("styleoptions", styleoptions);
          gradian_container_style.background = "transparent";
          gradian_container_style.top = "unset";
          gradian_container_style.width = "380px";
          gradian_container_style.height = "540px";
          gradian_container_style.minHeight = "380px";
          gradian_container_style.bottom = styleoptions.main.bottom;
          gradian_container_style.right = styleoptions.main.right;
          gradian_container_style.left = styleoptions.main.left;
        }
      }
    };

    // Add event listener
    window.addEventListener("message", handleMessage);

    // Clean up by removing the event listener when component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  async function toggleIframe() {
    const frameEl = document.getElementById("previewSurveyFrame");
    const containerEl = document.getElementById("_gradian_container");
    if (containerEl && containerEl.style.display === "none") {
      containerEl.style.display = "block";
    }
    console.log("#345.2 frameEl", survey.survey_options);
    if (
      (survey.survey_options.show_introduction || survey.survey_options.introduction || survey.questions.length > 0) &&
      frameEl.style.display === "block"
    ) {
      return null;
    } else if (
      (survey.survey_options.show_introduction || survey.survey_options.introduction || survey.questions.length > 0) &&
      frameEl.style.display === "none"
    ) {
      frameEl.style.display = "block";
    } else {
      frameEl.style.display = "none";
    }
  }
  function handleIframeRefresh() {
    const frameEl = document.getElementById("previewSurveyFrame");
    const containerEl = document.getElementById("_gradian_container");
    if (containerEl && containerEl.style.display === "none") {
      containerEl.style.display = "block";
    }
    if (frameEl) frameEl.src += "";
  }

  async function handleIframeLoad() {
    console.log("***iframeloaded");

    toggleIframe();
    var obj = JSON.parse(
      JSON.stringify({
        message: "surveyData",
        params: { data: survey, cq: currentQuestion, cp: currentProduct },
        channel: "_gradian",
      })
    );
    await window.frames["previewSurveyFrame"].contentWindow.postMessage(obj, "*");
  }

  console.log("survey in PreviewSurvey is", survey);
  //window.frames['inlineFrameExample'].contentWindow.postMessage(survey, '*');

  return (
    <div style={{ marginTop: "50px" }}>
      <div className={style.browserMockup} style={{ height: "650px", width: "100%", position: "relative" }}>
        <div className={style.browserBar}>
          <div className={style.browserButtons}>
            <div className={style.button + " " + style.red}></div>
            <div className={style.button + " " + style.yellow}></div>
            <div className={style.button + " " + style.green}></div>
          </div>
          <div className={style.addressBar}>
            <span className={style.addressText}>
              {platform === "web" ? "https://yourwebsite.com" : "https://survey.feedbackspark.com/aasbbnhjukinnmlqopui"}
            </span>
          </div>
        </div>
        <div className={style.content}>
          <div
            id="_gradian_container"
            className={`${style._gradian_container} ${survey.platform === "link" ? style._surveylink : ""}`}
            style={{
              position: "absolute",
              // transition: "all 0.3s ease-in-out",
              //transition: "width 0.3s ease-in-out, height 0.3s ease-in-out",
            }}
          >
            <div id="_gradian_inner_container" className={style._gradian_inner_container}>
              <div id="_gradian_main" className={`${style.iframeParent} ${style._gradian_main}`}>
                <iframe
                  id="previewSurveyFrame"
                  title="Inline Frame Example"
                  // width="400"
                  //height="400"

                  className={style.previewSurveyClass}
                  onLoad={handleIframeLoad}
                  src={process.env.REACT_APP_SERVER_URL + "surveyhq-preview.html"}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
