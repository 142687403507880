import React, { useState, useEffect } from "react";
import InstallScript from "../../components/InstallScript";
import UploadImage from "../../components/UploadImage";
import { deleteProductLogo } from "../../api/Account";
import { useAuthDispatch } from "../../context/AuthContext";
import { Text, Anchor, Modal, Group, Card, Table, Box, Space, Divider, Button, Image } from "@mantine/core";
import { useAuthState } from "../../context/AuthContext";
import { getFromLocalStorage, pluralize } from "../../utils/helpers";
import CopyValueIcon from "../../components/CopyValueIcon";

import Heading from "../../components/Heading";
import { IconTrash } from "@tabler/icons-react";

export default function Organization() {
  const auth = useAuthState();
  const dispatch = useAuthDispatch();
  const [modalOpened, setModalOpened] = useState(false);
  const [uploadModalOpened, setUploadModalOpened] = useState(false);

  const currentUser = auth.user;
  const organization = auth.organization;
  const currentProduct = auth.currentProduct;
  const userHasPermission = currentUser.isAccountOwner || currentUser.accessLevel == 1;
  const environment = getFromLocalStorage("FSEnv") || "prod";

  return (
    <>
      <Heading title="Organization" showDivider={true} />

      {organization.products.length > 1 && (
        <Text size="md" color="dimmed" fontWeight={400}>
          Products
        </Text>
      )}

      <Card
        withBorder
        radius="md"
        padding="xl"
        sx={theme => ({
          backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
        })}
      >
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Website</th>
              <th>{environment === "dev" ? "Sandbox Token" : "Production Token"}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{currentProduct.name}</td>
              <td>{currentProduct.website}</td>
              <td>
                <Group>
                  {currentProduct.token} <CopyValueIcon value={currentProduct.token} />
                </Group>
              </td>
              <td>
                <Modal opened={modalOpened} onClose={() => setModalOpened(false)} size="auto">
                  <InstallScript token={currentProduct.token} />
                </Modal>

                <Group position="center">
                  <Anchor onClick={() => setModalOpened(true)}>View Installation Code</Anchor>
                </Group>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
      <Space h="lg" />

      <Card
        withBorder
        radius="md"
        padding="xl"
        sx={theme => ({
          backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
        })}
      >
        <Text size="16px" weight={700} color="rgb(73, 80, 87)">
          Logo
        </Text>
        <Divider />
        <Box p={20} pl={0}>
          <Text size="sm">Logo will be used in survey pages.</Text>
        </Box>
        <Modal opened={uploadModalOpened} onClose={() => setUploadModalOpened(false)} size="auto">
          <Box>
            <UploadImage
              token={currentProduct.token}
              ct={auth.csrftoken}
              //setUploadModalOpened={setUploadModalOpened}
              //dispatch={dispatch}
            />
          </Box>
        </Modal>
        {console.log("OP is", currentProduct.logoImg)}

        <div>
          {currentProduct.logoImg && (
            <Group>
              <Image width={200} src={currentProduct.logoImg} />
              {userHasPermission && (
                <IconTrash
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    currentProduct.logoImg = null;
                    console.log("cp2", currentProduct);

                    deleteProductLogo(currentProduct.token, auth.csrftoken);
                    dispatch({ type: "DELETE_PRODUCT_LOGO", payload: currentProduct });
                  }}
                />
              )}
            </Group>
          )}
        </div>

        <Group mt={20} position="left">
          {userHasPermission && (
            <Button onClick={() => setUploadModalOpened(true)}>
              {currentProduct.logoImg ? "Update Logo" : "Upload Logo"}
            </Button>
          )}
        </Group>
      </Card>
    </>
  );
}
