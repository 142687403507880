import React, { useEffect, useState } from "react";
import { SurveyForm } from "./components/form";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mantine/core";
import { getSurveyById } from "./../../api/Survey";
import { FullPageSpinner } from "../../components/spinners";
import { showErrorNotification } from "../../components/notifications";

export default function EditSurvey() {
  let { surveyId } = useParams();
  const navigate = useNavigate();
  const [surveyData, setSurveyData] = useState();
  const [loading, setLoading] = useState(false);
  console.log("surveyid in edit", surveyId);

  useEffect(() => {
    setLoading(true);
    getSurveyById(surveyId)
      .then(data => {
        if (data.error) {
          console.log("#990", data);
          if (data.status === 404) {
            showErrorNotification("We could not find this survey. Please check if you are in the right environment.");
            navigate("/surveys");
          } else {
            showErrorNotification("Failed to get the survey.");
          }
        } else {
          setSurveyData(data);
        }
      })
      .catch(error => {
        console.log(error);
        showErrorNotification();
      })
      .finally(setLoading(false));
  }, [surveyId]);

  const output = () => {
    if (!loading) {
      return <Box mt={30}>{surveyData && <SurveyForm survey={surveyData} editSurveyId={surveyId} />}</Box>;
    } else {
      return <FullPageSpinner />;
    }
  };

  return output();
}
