import React, { useState } from "react";
import InstallScript from "./InstallScript";

import { Group, Text, Button, Modal } from "@mantine/core";
import { Announcement } from "./Announcement";

export function InstallCodeBanner(props) {
  const { token } = props;
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <Announcement>
      <Group>
        <Text>You are one step away from getting insights from your users.</Text>
        <Button size="xs" onClick={() => setModalOpened(true)}>
          Install FeedbackSpark
        </Button>
      </Group>
      <Modal opened={modalOpened} onClose={() => setModalOpened(false)} size="auto">
        <InstallScript token={token} />
      </Modal>
    </Announcement>
  );
}
