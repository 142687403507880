import React from "react";
import { Group, Card, Text, Radio, Space, Chip, useMantineTheme } from "@mantine/core";
import { StyledSelect } from "../../../../components/MantineOverride";
// import { useIntl, FormattedMessage } from "react-intl";
import { userIdentifiers, userIdentifierTag } from "../../../../utils/config";
import { capitalize } from "../../../../utils/helpers";
import FSToolTip from "../../../../components/FSToolTip";

import { SubHeadingText } from "../../../../components/MantineOverride";

export function Audience({ dispatch, formState }) {
  let options = formState.surveyOptions;

  const theme = useMantineTheme();

  function handleChange(val) {
    dispatch({ type: "updateSurveyOptions", payload: { key: "userIdentifierKey", value: val } });
    if (val === userIdentifiers["uid"]) {
      dispatch({ type: "removeSurveyOptions", payload: { key: "email" } });
    }
  }

  function handleIdentifyUsers(val) {
    if (val === "false") {
      dispatch({ type: "removeSurveyOptions", payload: { key: "userIdentifierKey" } });
    }
    dispatch({ type: "updateSurveyOptions", payload: { key: "identifyRespondents", value: val } });
  }

  function handleInputChange(value, section, field) {
    console.log("$123 value in radio group input is", value);
    dispatch({
      type: "settingChange",
      value: value,
      section: section,
      field: field,
    });
  }

  return (
    <>
      <Card shadow="sm" p="lg" radius="md" mt="md" mb="xs" withBorder>
        <SubHeadingText>Identify respondents?</SubHeadingText>

        <Radio.Group
          value={options.identifyRespondents}
          onChange={val => handleIdentifyUsers(val)}
          name="isAnonymous"
          orientation="horizontal"
          spacing="xs"
        >
          <Card p={8} withBorder>
            <Radio value="true" label="Yes" />
          </Card>
          <Card p={8} withBorder>
            <Radio value="false" label="No" />
          </Card>
        </Radio.Group>
        <Space h="md" />
        {options.identifyRespondents === "true" && (
          <Card
            sx={{
              backgroundColor: theme.colorScheme === "dark" ? "#1c1c1c" : "#f5f5f5",
              color: theme.colorScheme === "dark" ? "#fff" : "#000",
              overflow: "visible",
            }}
          >
            <Text size={14} color="#5c5f66">
              Select user identifier key
            </Text>
            <Chip.Group value={options.userIdentifierKey} onChange={val => handleChange(val)}>
              <Chip value={userIdentifiers["email"]}>respondent_email</Chip>
              <Chip value={userIdentifiers["uid"]}>respondent_id</Chip>
            </Chip.Group>
            <Space h="md" />
            {options.userIdentifierKey === userIdentifiers["email"] && (
              <Card
                p={0}
                sx={{
                  backgroundColor: theme.colorScheme === "dark" ? "#1c1c1c" : "#f5f5f5",
                  color: theme.colorScheme === "dark" ? "#fff" : "#000",
                  overflow: "visible",
                }}
              >
                <Group>
                  <StyledSelect
                    // dropdownPosition="bottom"
                    size="xs"
                    value={options.email?.platform}
                    onChange={val => handleInputChange(val, "email", "platform")}
                    label="Select email delivery platform to customize email tag"
                    placeholder="Pick one"
                    data={[
                      { value: "zendesk", label: "Zendesk", icon: "" },
                      { value: "sendgrid", label: "Sendgrid", icon: "" },
                      { value: "mailchimp", label: "Mailchimp", icon: "" },
                      { value: "mailgun", label: "Mailgun", icon: "" },
                      { value: "other", label: "Other", icon: "" },
                    ]}
                  />
                </Group>
              </Card>
            )}

            {options.userIdentifierKey == userIdentifiers["email"] && options.email && (
              <Text size={14} color="dimmed">
                {options.userIdentifierKey === userIdentifiers["email"] && options.email?.platform === "other"
                  ? "Set value of email in the survey url."
                  : `${capitalize(options.email?.platform)} email tag has been added to the survey url.`}
              </Text>
            )}
            {options.userIdentifierKey == userIdentifiers["uid"] && (
              <Group align="flex-start" spacing={4}>
                <Text size={14} color="dimmed">
                  Set value of respondent_id in the survey url.
                </Text>
                <FSToolTip label="Value will be any internal ID that you associate with the user, for e.g. user_id,username etc." />
              </Group>
            )}
            {options.userIdentifierKey && (
              <Text size={14} color="dimmed">
                Example:&nbsp;https://survey.feedbackspark.com/[surveyCode]/?
                <Text fw={500} span color="#000">
                  {userIdentifierTag(options.userIdentifierKey, options.email?.platform)}
                </Text>
              </Text>
            )}
            {!options.userIdentifierKey && (
              <Text size={14} color="dimmed">
                Identifier will be added to the survey url.
              </Text>
            )}
          </Card>
        )}
      </Card>
    </>
  );
}
