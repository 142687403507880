import React from "react";
import { Link } from "react-router-dom";
import { Text, Paper, Badge, Group, Title, Grid, Button, Space, Box } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { templateList } from "../../data/TemplateList";
import Heading from "../../components/Heading";

export default function Survey() {
  return (
    <Box>
      <Heading title="New Survey" subheading="Use a template from below or create from scratch." showDivider={true}>
        <Link to="blank">
          <Button leftIcon={<IconPlus />}>Create Survey from scratch</Button>
        </Link>
      </Heading>
      <Space h="md" />

      <Grid>
        {templateList.map((template, idx) => {
          return (
            <Grid.Col key={idx} md={6} lg={3}>
              <Paper shadow="sm" p="lg" radius="md" withBorder sx={{ height: 140 }} component={Link} to={template.slug}>
                <Group>
                  <Title order={3}>{template.title}</Title>
                  {/* <Badge>{template.badge}</Badge> */}
                </Group>
                <Group>
                  <Text size="xs" color="gray">
                    {template.description}
                  </Text>
                </Group>
              </Paper>
            </Grid.Col>
          );
        })}
      </Grid>
    </Box>
  );
}
