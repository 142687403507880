import React from "react";

import { Prism } from "@mantine/prism";

import vsLight from "prism-react-renderer/themes/vsLight";
import vsDark from "prism-react-renderer/themes/vsDark";
import { Text, Code, Space, Tabs, Group } from "@mantine/core";
import { useIntl, FormattedMessage } from "react-intl";

//import Highlight, { defaultProps } from 'prism-react-renderer';
//import vsLight from 'prism-react-renderer/themes/duotoneLight';

export default function InstallScript(props) {
  const { token } = props;
  const intl = useIntl();

  const trackingCode = `
    <script type="text/javascript">
    (function (g, r, a, d, i, an) {
      if (window['spark']) return;
      g["_shq"] = d;
      g[d] =
        g[d] ||
        function () {
          (g[d].q = g[d].q || []).push(arguments);
        };
      i = r.getElementsByTagName("head")[0];
      an = r.createElement("script");
      an.id='_shq';
      an.async = 1;
      an.src = a;
      i.appendChild(an);
    })(window, document, "https://static.feedbackspark.com/spark.js" ,'spark');
    spark('init',"${token}");
    spark('setUser', USER_ID); //${intl.formatMessage({
      id: "connect_page.recommeneded",
      defaultMessage: "Recommended",
    })}

  </script>`;

  return (
    <div>
      {/* <Title order={3} mt={20}>
        Install on your website
      </Title> */}
      <Space h="md" />
      <Tabs defaultValue="html">
        <Tabs.List>
          <Tabs.Tab value="html">HTML</Tabs.Tab>
          <Tabs.Tab value="npm">NPM/Yarn</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="html" pt="xs">
          <Space h="md" />
          <Text color="dimmed" size="sm" align="left" mb={20}>
            <FormattedMessage
              id="connect_page.html_help_text"
              values={{
                mark: chunks => <Code color={"yellow"}>{chunks}</Code>,
                html_tag: `<head>`,
              }}
              defaultMessage={
                "Copy the code below and paste it between the <head> tags on all the pages where you want to show the survey."
              }
            />
          </Text>

          <Group>
            <Prism
              withLineNumbers
              language="javascript"
              sx={{ border: "1px dotted #ddd", flexGrow: 0.1 }}
              getPrismTheme={(_theme, colorScheme) => (colorScheme === "light" ? vsLight : vsDark)}
            >
              {trackingCode}
            </Prism>
          </Group>
        </Tabs.Panel>

        <Tabs.Panel value="npm" pt="xs">
          <Space h="md" />
          <Text size={14}>NPM</Text>
          <Group>
            <Prism
              language="javascript"
              sx={{ border: "1px dotted #ddd", flexGrow: 0.1 }}
              getPrismTheme={(_theme, colorScheme) => (colorScheme === "light" ? vsLight : vsDark)}
            >
              npm install --save feedbackspark
            </Prism>
          </Group>
          <Space h="md" />
          <Text size={14}>Yarn</Text>
          <Group>
            <Prism
              language="javascript"
              sx={{ border: "1px dotted #ddd", flexGrow: 0.16 }}
              getPrismTheme={(_theme, colorScheme) => (colorScheme === "light" ? vsLight : vsDark)}
            >
              yarn add feedbackspark
            </Prism>
          </Group>
          <Space h="md" />
          <Space h="md" />
          <Text size={14}>
            {intl.formatMessage({
              id: "connect_page.npm_initialize_spark",
              defaultMessage: "Next in your application, as early as possible, initialize Spark.",
            })}
          </Text>
          <Group>
            <Prism
              language="javascript"
              sx={{ border: "1px dotted #ddd", flexGrow: 0.16 }}
              getPrismTheme={(_theme, colorScheme) => (colorScheme === "light" ? vsLight : vsDark)}
            >
              {`import spark from "feedbackspark";\nspark("init", "${token}"); `}
            </Prism>
          </Group>
        </Tabs.Panel>
      </Tabs>

      <Space h="md" />
      <Space h="md" />
    </div>
  );
}
