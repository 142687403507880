import React from "react";
import { useAuthState } from "../../context/AuthContext";
import InstallScript from "../../components/InstallScript";
import { Box } from "@mantine/core";
import Heading from "../../components/Heading";
import { useIntl } from "react-intl";

function Connect() {
  const intl = useIntl();
  const auth = useAuthState();

  return (
    <>
      <Box>
        <Heading title={intl.formatMessage({ id: "connect_page.heading", defaultMessage: "Connect" })} />

        <InstallScript token={auth.currentProduct.token || auth.organization.products[0].token} />
      </Box>
    </>
  );
}

export default Connect;
