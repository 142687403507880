import { API_URLS } from "../utils/constants";
import API from "./API";
import { camelizeKeys } from "humps";

export async function getCSRF(dispatch) {
  let response = await API.get(API_URLS.GET_CSRF);

  if (response.success) {
    let data = response.success;
    console.log("success response headers", data.headers);

    dispatch({ type: "SET_CSRF", payload: data.headers["x-csrftoken"] });
    return data.headers["x-csrftoken"];
  } else {
    let data = response.error;
    console.log("it seems something is wrong", data);
    dispatch({ type: "AUTH_ERROR", error: data });
  }
}

export async function logoutUser(event, dispatch) {
  event.preventDefault();
  dispatch({ type: "AUTH_REQUEST" });
  let response = await API.get(API_URLS.LOGOUT_USER);
  if (response.success) {
    window.localStorage.clear();
    dispatch({ type: "LOGOUT" });
    //window.location.pathname = '/login';

    //navigate('/login', { replace: false });
  } else {
    dispatch({ type: "AUTH_ERROR", error: response.error });
  }
}

export async function getCurrentUser(dispatch, currentEnv) {
  let response = await API.get(API_URLS.GET_CURRENT_USER, { env: currentEnv });
  if (response.success) {
    console.log("CAMELIZE GETUSER", camelizeKeys(response.success.data.user));
    dispatch({ type: "AUTH_CHECK", payload: camelizeKeys(response.success.data.user) });
    return camelizeKeys(response.success.data.user);
  } else {
    let data = response.error;
    console.log("error in getCurrentUser", response);
    dispatch({ type: "AUTH_ERROR", error: data });
  }
}

export async function validateResetPasswordToken(resetToken, csrfToken) {
  console.log("token validator called with csrfToke", csrfToken);
  let response = await API.post(API_URLS.VALIDATE_RESET_PASSWORD_TOKEN, { token: resetToken }, csrfToken);
  if (response.success) {
    //console.log('$$$$$ Success Response $$$$', response.success);
    return response.success;
  } else if (response.error) {
    //console.log('##### Error Response #####', response.error);
    return response.error;
  }
}

export async function validateBetaInviteToken(token, csrfToken) {
  console.log("token validator called with csrfToke", csrfToken);
  let response = await API.post(API_URLS.VALIDATE_BETA_INVITE_TOKEN, { token: token }, csrfToken);
  if (response.success) {
    //console.log('$$$$$ Success Response $$$$', response.success);
    return response.success;
  } else if (response.error) {
    //console.log('##### Error Response #####', response.error);
    return response.error;
  }
}
