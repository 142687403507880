import dayjs from "dayjs";

export const QUESTION_TYPE_STRING_TO_ID = {
  open_text: 1,
  mcq_single: 2,
  mcq_multi: 3,
  nps: 4,
  rating: 5,
  binary: 6,
  csat: 7,
  ces: 8,
  matrix: 9,
  ranking: 10,
  image_choice: 11,
  email: 12,
  statement: 29,
  redirect: 30,
};

export const ACCESS_LEVELS = {
  1: "Editor",
  2: "Developer",
  3: "Viewer",
};
export const SURVEY_STATES = { 0: "In Draft", 1: "Live", 2: "Paused", 3: "Completed", 4: "Archived" };

export const ItemTypes = {
  QUESTION: "question",
};

export const ratingIconMap = {
  emoji: {
    3: { 1: "😟", 2: "😐", 3: "😊" },
    5: { 1: "😡", 2: "😟", 3: "😐", 4: "😊", 5: "😄" },
  },
  star: {
    3: { 1: "★", 2: "★★", 3: "★★★" },
    5: { 1: "★", 2: "★★", 3: "★★★", 4: "★★★★", 5: "★★★★★" },
    10: {
      1: "★",
      2: "★★",
      3: "★★★",
      4: "★★★★",
      5: "★★★★★",
      6: "★★★★★★",
      7: "★★★★★★★",
      8: "★★★★★★★★",
      9: "★★★★★★★★★",
      10: "★★★★★★★★★★",
    },
  },
};

export const NOTIFICATION_AUTO_CLOSE = 2000;

// const deptData = [
//   'Product',
//   'User Research',
//   'Engineering',
//   'Design',
//   'Marketing',
//   'Sales',
//   'Customer Success',
//   'Other',
// ];

export const API_URLS = {
  // use forward slash at the end for Django.
  GET_USER_ATTRIBUTES: token => `organization/${token}/`,
  GET_TEAM_MEMBERS: "team/",
  SEND_TEAM_INVITE: "team/", //POST
  //DELETE_TEAM_MEMBER: id => `team/${id}/`,
  UPDATE_TEAM_MEMBER: id => `team/${id}/`, // PATCH and DELETE
  VALIDATE_TEAM_INVITE_TOKEN: "invite/validate_token/", //POST
  VALIDATE_BETA_INVITE_TOKEN: "betainvite/validate_token/", //POST
  CONFIRM_TEAM_INVITE: "invite/confirm/",
  //RESEND_TEAM_INVITE: 'invite/',
  GET_CSRF: "gct/", //GET
  LOGOUT_USER: "logout/", //GET
  LOGIN_USER: "login/", //POST
  GET_CURRENT_USER: "user/me/", //GET

  SIGNUP: "signup/",

  GET_TAGGED_RESPONSES: (surveyId, dateRange, questionId) =>
    `surveys/${surveyId}/questions/${questionId}/responses/?start_date=${dateRange[0]}&end_date=${dateRange[1]}`, //used in TextualReport.js
  GET_PAGINATED_RESPONSES: token => `surveys/respondents/${token}/`, // used in pages/data/Respondents.js
  GET_QUESTION_RESPONSES: (surveyId, questionId) => `surveys/${surveyId}/questions/${questionId}/responses/`, // used in all report pages MCQReport, RatingReport,TextualReport
  SURVEYS: "surveys/", // GET and POST
  UPDATE_SURVEY: id => `surveys/${parseInt(id)}/`, // PATCH,PUT and DELETE
  CREATE_SURVEYCAMPAIGN: "campaigns/", //POST
  GET_SURVEYCAMPAIGN: "campaigns/", //GET
  UPDATE_CAMPAIGN: id => `campaigns/${id}/`, //PATCH
  GET_SURVEYS_WITH_SUMMARY: token => `surveys/${token}/`, // used in surveyList
  GET_SURVEY_BY_ID: id => `surveys/${parseInt(id)}/`, //used in EditSurvey.js and SurevyReponse.js
  GET_SURVEY_STATS: (surveyId, timeframe, dateRange) =>
    `surveys/${surveyId}/stats/?start_date=${dateRange[0]}&end_date=${dateRange[1]}`, //used in SurveyStats.js
  EXPORT_DATA_AS_CSV: surveyId => `surveys/${surveyId}/responses/export`,
  GET_RESPONDENT_ATTRIBUTE_VALUES: (token, surveyId) =>
    `surveys/${surveyId}/respondents/attribute/values/?token=${token}`,
  DELETE_SURVEY_RESPONSE: (surveyId, sa_id) => `surveys/${surveyId}/responses/${sa_id}/`, //DELETE
  //GET_AGGREGATE_RESPONSES: surveyId => `surveys/${surveyId}/aggregate/`, //#CHECK not used anywhere.
  //GET_SURVEY_RESPONSES: id => `surveys/${id}/responses/`, // #CHECK: it is not used anywhere.

  CONFIRM_RESET_PASSWORD_TOKEN: "password_reset/confirm/",
  VALIDATE_RESET_PASSWORD_TOKEN: "password_reset/validate_token/", //POST
  SEND_RESET_TOKEN: "password_reset/",

  GET_ORGANIZATION: token => `organization/${token}/`, //GET
  UPDATE_ORGANIZATION: token => `organization/${token}/`, //PATCH
  UPDATE_PRODUCT: token => `products/${token}/`,
  UPLOAD_PRODUCT_LOGO: token => `products/${token}/upload/`, //PATCH
  DELETE_PRODUCT_LOGO: token => `products/${token}/deletelogo/`, //DELETE

  GET_SLACK_TOKEN: (code, state, redirect_uri) =>
    `integrations/slack/?code=${code}&state=${state}&redirect_uri=${redirect_uri}`,
  GET_SLACK_CHANNELS: () => `integrations/slack/channels/`,
  GET_SAVE_SURVEY_NOTIFICATIONS: () => `notifications/`,
  UPDATE_SURVEY_NOTIFICATION: notification_id => `notifications/${notification_id}/`,
  GET_API_KEY: (product_token, env) => `products/${product_token}/api_key/?env=${env}`,
};
