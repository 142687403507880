import React, { createContext, useContext, useEffect, useReducer } from "react";

import { getCSRF, getCurrentUser } from "../api/Auth";
import { initialState, AuthReducer } from "./../reducers/AuthReducer";
import { useBrowserStorage } from "../hooks/useBrowserStorage";
import { objectIsEqual } from "./../utils/helpers";
import { getFromLocalStorage } from "./../utils/helpers";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }

  return context;
}
const AuthProvider = ({ children }) => {
  const [auth, dispatch] = useReducer(AuthReducer, initialState);
  const [currentProduct, setCurrentProduct] = useBrowserStorage("Local", "currentProduct", null);
  let currentEnv = getFromLocalStorage("FSEnv", "prod");

  useEffect(() => {
    if (auth.user === null) {
      getCurrentUser(dispatch, currentEnv)
        .then(response => {
          console.log(">>>>response in AuthContext>>>", response);
          if (response === undefined) {
            return;
          }

          if (currentProduct == null || !objectIsEqual(currentProduct, response.organization.products[0])) {
            console.log(">>>>setting CURRENT PRODUCTt>>>", response.organization.products[0]);
            setCurrentProduct(response.organization.products[0]);
            dispatch({ type: "SET_CURRENT_PRODUCT", payload: response.organization.products[0] });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [auth.user, currentEnv]);

  return (
    <AuthStateContext.Provider value={auth}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export { getCSRF, AuthProvider, useAuthDispatch, useAuthState };
