import React, { useState } from "react";
import {
  Modal,
  Text,
  Box,
  Card,
  Group,
  Badge,
  ActionIcon,
  Button,
  TextInput,
  List,
  ThemeIcon,
  Anchor,
} from "@mantine/core";
import { capitalize } from "../../../utils/helpers";
import { IconX, IconPlus, IconArrowNarrowLeft, IconArrowNarrowRight, IconPointFilled } from "@tabler/icons-react";
import { badgeColor, translatedSurveyStates, surveyPlatformIcon } from "./utils";
import { useIntl } from "react-intl";
import { useAuthState } from "../../../context/AuthContext";
import { API_URLS } from "../../../utils/constants";
import API from "../../../api/API";
import { showErrorNotification, showSuccessNotification } from "../../../components/notifications";
import { TextLink } from "../../../components/UI";

export function CampaignModal(props) {
  const { campaign, campaignModalOpened, setCampaignModalOpened, surveyList, setRefresh, isEditing } = props;
  console.log("CampaignModal props", props);

  const intl = useIntl();
  const auth = useAuthState();
  const csrfToken = auth.csrftoken;
  const currentProduct = auth.currentProduct || auth.organization.products[0];
  const token = currentProduct ? currentProduct.token : auth.organization.products[0].token;
  const [campaignName, setCampaignName] = useState(isEditing && campaign ? campaign.name : "");
  const [selectedSurveys, setSelectedSurveys] = useState(isEditing && campaign ? campaign.surveys : []);
  const [step, setStep] = useState(1);

  function handleClose() {
    setCampaignModalOpened(false);
    setStep(1);
    if (!isEditing) {
      setSelectedSurveys([]);
    }
  }

  function handleNext() {
    if (step === 1 && selectedSurveys.length < 2) return;
    if (step === 2) return;
    setStep(step + 1);
  }
  function handleBack() {
    if (step === 1) return;

    setStep(step - 1);
  }

  async function handleUpdateCampaign(event, id) {
    event.preventDefault();
    let response = await API.put(
      API_URLS.UPDATE_CAMPAIGN(id),
      { surveys: selectedSurveys.map(s => s.id), name: campaignName },
      csrfToken,
      { t: token }
    );
    if (response.error) {
      console.log(response.error.error);
      showErrorNotification("Some surveys are already part of a campaign. Please remove them and try again.");
      return;
    } else {
      showSuccessNotification("Campaign updated successfully");
      handleClose();
      setRefresh(prev => prev + 1);

      return;
    }
  }

  async function handleCreateCampaign(event) {
    event.preventDefault();
    if (selectedSurveys.length < 2) return;
    if (!campaignName) return;
    console.log("Create Campaign");

    let response = await API.post(
      API_URLS.CREATE_SURVEYCAMPAIGN,
      { surveys: selectedSurveys.map(s => s.id), name: campaignName },
      csrfToken,
      { t: token }
    );

    if (response.error) {
      console.log(response.error.error);
      showErrorNotification("Some surveys are already part of a campaign. Please remove them and try again.");
      return;
    } else {
      showSuccessNotification("Campaign created successfully");
      handleClose();
      setRefresh(prev => prev + 1);

      return;
    }
  }

  const toggleSurvey = survey => {
    if (selectedSurveys.find(s => s.id === survey.id)) {
      setSelectedSurveys(selectedSurveys.filter(s => s.id !== survey.id));
    } else {
      setSelectedSurveys([...selectedSurveys, survey]);
    }
  };

  return (
    <Modal
      size="60%"
      opened={campaignModalOpened}
      title="Create Campaign"
      overflow="inside"
      onClose={handleClose}
      styles={() => ({
        header: {
          fontSize: "20px",
          fontWeight: 700,
          alignItems: "center",
          padding: "0px 40px 20px 40px",
          borderBottom: "1px solid #CED4DA",

          textAlign: "center",
        },
        root: {
          //maxHeight: "80vh",
        },
        body: {
          display: "flex",
          flexDirection: "column",
          // maxHeight: "80vh",
          // height: "700px",
          flex: 1,
          overflowY: "auto",
        },
      })}
    >
      {step === 1 && (
        <>
          <div style={{ padding: "0px 40px 0px 40px" }}>
            <CampaignStepOne
              surveyList={surveyList}
              selectedSurveys={selectedSurveys}
              toggleSurvey={toggleSurvey}
              intl={intl}
              handleNext={handleNext}
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",

              left: 0,
              right: 0,
              backgroundColor: "white",
              zIndex: 1,
              padding: "20px 20px",
              marginBottom: "20px",
            }}
          >
            <Group position="center">
              <Button
                disabled={selectedSurveys.length < 2}
                onClick={handleNext}
                rightIcon={<IconArrowNarrowRight size={18} />}
              >
                Next
              </Button>
            </Group>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <div style={{ padding: "0px 40px 0px 40px" }}>
            <CampaignStepTwo handleBack={handleBack} campaignName={campaignName} setCampaignName={setCampaignName} />
          </div>
          <Group position="apart">
            <Button variant="subtle" onClick={handleBack} leftIcon={<IconArrowNarrowLeft size={18} />}>
              <Text size={14}>Back</Text>
            </Button>
            {isEditing ? (
              <Button onClick={e => handleUpdateCampaign(e, campaign.id)}>Update Campaign</Button>
            ) : (
              <Button onClick={e => handleCreateCampaign(e)}>Create Campaign</Button>
            )}
          </Group>
        </>
      )}
    </Modal>
  );
}

function CampaignStepOne(props) {
  const { surveyList, selectedSurveys, toggleSurvey, intl } = props;
  return (
    <div>
      <Group position="right" mb={10}>
        <Anchor href="https://feedbackspark.com/docs/campaigns/" target="_blank" underline={false} size={14}>
          Learn more
        </Anchor>
      </Group>

      {selectedSurveys.length > 0 && (
        <Text align="left" fw={700} size={14} mb={10}>
          Surveys in the campaign
        </Text>
      )}
      {selectedSurveys.length === 0 ? (
        <Box
          sx={theme => ({
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
            textAlign: "center",
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
            cursor: "pointer",
            border: "4px dashed #CED4DA",

            marginBottom: "100px",
            //margin: "10px 60px 10px 60px",
          })}
        >
          Select surveys to group them for combined tracking.
          <Group position="center">
            <List
              sx={{ textAlign: "left", lineHeight: 1 }}
              spacing={0}
              icon={
                <ThemeIcon color="#ddd" size={14} radius="xl">
                  <IconPointFilled size={14} color="black" />
                </ThemeIcon>
              }
            >
              <List.Item>
                <Text size={14} color="dimmed">
                  Only one survey per channel is allowed.
                </Text>
              </List.Item>
              <List.Item>
                <Text size={14} color="dimmed">
                  A survey can only be part of one campaign.
                </Text>
              </List.Item>
            </List>
          </Group>
        </Box>
      ) : (
        <div style={{ marginBottom: "100px" }}>
          {selectedSurveys.map(survey => (
            <Card key={survey.id} shadow="xs" radius="md" padding="sm" mt={20} withBorder>
              <Group position="apart">
                <Group>
                  {surveyPlatformIcon(survey.platform)}
                  {survey.title}
                  <Card bg="#f1f5f9" p={0} px={6} py={4}>
                    <Text lh={1} size={12} color="dimmed">
                      {capitalize(survey.platform)}
                    </Text>
                  </Card>
                  <Badge color={badgeColor(survey.state)}>{translatedSurveyStates(intl, survey.state)}</Badge>
                </Group>
                <ActionIcon
                  onClick={e => {
                    e.stopPropagation();
                    toggleSurvey(survey);
                  }}
                >
                  <IconX size={18} />
                </ActionIcon>
              </Group>
            </Card>
          ))}
        </div>
      )}
      {selectedSurveys.length < 3 && (
        <Text align="left" fw={700} size={14} mt={30}>
          Add Surveys
        </Text>
      )}

      <Box
        shadow="xs"
        sx={{
          overflow: "scroll",
          // maxHeight: "30vh",
          maxHeight: "300px",

          //borderTop: "1px solid #CED4DA",
          boxShadow: "0 2px 2px -2px #CED4DA",
        }}
      >
        {surveyList
          .filter(s => !selectedSurveys.find(selected => selected.id === s.id))
          .filter(s => !selectedSurveys.find(selected => selected.platform === s.platform))
          .filter(survey => !survey.campaign_id)
          .map(survey => (
            <Card key={survey.id} shadow="xs" radius="md" padding="sm" mt={20} withBorder>
              <Group position="apart">
                <Group>
                  {surveyPlatformIcon(survey.platform)}
                  {survey.title}
                  <Card bg="#f1f5f9" p={0} px={6} py={4}>
                    <Text lh={1} size={12} color="dimmed">
                      {capitalize(survey.platform)}
                    </Text>
                  </Card>
                  <Badge color={badgeColor(survey.state)}>{translatedSurveyStates(intl, survey.state)}</Badge>
                </Group>

                <ActionIcon
                  onClick={e => {
                    e.stopPropagation();
                    toggleSurvey(survey);
                  }}
                >
                  <IconPlus size={18} />
                </ActionIcon>
              </Group>
            </Card>
          ))}
      </Box>
    </div>
  );
}

function CampaignStepTwo(props) {
  const { campaignName, setCampaignName } = props;

  return (
    <div>
      <TextInput
        value={campaignName}
        onChange={event => setCampaignName(event.currentTarget.value)}
        placeholder="Q1 NPS"
        label="Name your campaign"
        withAsterisk
        mb={20}
      />
    </div>
  );
}
