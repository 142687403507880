import React, { useState } from "react";
import { Text, Box, Popover } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

export default function FSToolTip({ label }) {
  const [popoverOpened, setPopoverOpened] = useState(false);

  return (
    <Popover width={200} trapFocus position="bottom" withArrow shadow="md" opened={popoverOpened}>
      <Popover.Target>
        <Box onMouseEnter={() => setPopoverOpened(true)} onMouseLeave={() => setPopoverOpened(false)}>
          <IconInfoCircle size="2rem" stroke={1.5} color="#aaa" />
        </Box>
      </Popover.Target>
      <Popover.Dropdown onMouseEnter={() => setPopoverOpened(true)} onMouseLeave={() => setPopoverOpened(false)}>
        <Text size="sm">{label}</Text>
      </Popover.Dropdown>
    </Popover>
  );
}
