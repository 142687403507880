import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
//import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from "./context/AuthContext";
import { ThemeProvider } from "./context/ThemeContext";
//import App from './App';
import { CustomFonts } from "./theme/CustomFonts";
import { SHQTheme } from "./theme";
import { FullPageSpinner } from "./components/spinners";
//Sentry start
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import * as Sentry from "@sentry/react";
// Sentry end
import { router } from "./router";
import "./style.scss";
import { IntlProvider } from "react-intl";
import { getLocale } from "./utils/helpers";
import { en, no, el, es } from "./i18n";

const translations = { en: en, es: es, no: no, el: el };

Sentry.init({
  dsn:
    process.env.REACT_APP_ENV === "local"
      ? ""
      : "https://d47188d8de1610dae284a23258ec4ab1@o4505954075934720.ingest.us.sentry.io/4505954079801344",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
  release: "1.0.0",
  environment: process.env.REACT_APP_ENV,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api.feedbackspark\.com\/*/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("main");

const root = ReactDOM.createRoot(container);

function getPreferredLanguage() {
  const language = getLocale().split("-")[0];
  return translations[language] ? language : "en";
}

const userLanguage = getPreferredLanguage();
const userLocale = getLocale();
console.log("#21 userLanguage", userLocale);

// import spark from "feedbackspark";
// spark("init", "nsZgjCv9bpX0gS6h");
// spark("setUser", "qwerty98879944777700");

// spark("setAttributes", {
//   plan: "Free",
//   isOld: true,
//   signup_date: "2025-02-01",
// });

root.render(
  <React.StrictMode>
    <AuthProvider>
      <IntlProvider messages={translations[userLanguage]} locale={userLanguage} defaultLocale="en">
        <MantineProvider withGlobalStyles withNormalizeCSS theme={SHQTheme}>
          <CustomFonts />

          <NotificationsProvider position="top-center" zIndex={2077} limit={5}>
            <ModalsProvider>
              <React.Suspense fallback={<FullPageSpinner />}>
                <ThemeProvider>
                  <RouterProvider
                    router={router}
                    fallbackElement={<FullPageSpinner />}
                    future={{ v7_startTransition: true }}
                  />
                </ThemeProvider>
              </React.Suspense>
            </ModalsProvider>
          </NotificationsProvider>
        </MantineProvider>
      </IntlProvider>
    </AuthProvider>
  </React.StrictMode>
);
