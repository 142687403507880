import React from "react";
import { Group, Select } from "@mantine/core";
import { IconLanguage, IconInfoCircle } from "@tabler/icons-react";
import { useIntl } from "react-intl";
import FSToolTip from "./FSToolTip";

export default function LanguageSelector({ dispatch, currentLang }) {
  const intl = useIntl();

  function handleLangChange(val) {
    console.log("langvalue 1", val);
    dispatch({ type: "updateLanguage", value: val });
  }

  const langList = [
    { value: "en", label: intl.formatMessage({ id: "survey.lang_english", defaultMessage: "English" }) },
    { value: "es", label: intl.formatMessage({ id: "survey.lang_spanish", defaultMessage: "Spanish" }) },
    { value: "no", label: intl.formatMessage({ id: "survey.lang_norwegian", defaultMessage: "Norwegian" }) },
    { value: "nl", label: intl.formatMessage({ id: "survey.lang_dutch", defaultMessage: "Dutch" }) },
    { value: "el", label: intl.formatMessage({ id: "survey.lang_greek", defaultMessage: "Greek" }) },
    { value: "fr", label: intl.formatMessage({ id: "survey.lang_french", defaultMessage: "French" }) },
    { value: "de", label: intl.formatMessage({ id: "survey.lang_german", defaultMessage: "German" }) },
    { value: "hi", label: intl.formatMessage({ id: "survey.lang_hindi", defaultMessage: "Hindi" }) },
    { value: "id", label: intl.formatMessage({ id: "survey.lang_indonesian", defaultMessage: "Indonesian" }) },
    { value: "it", label: intl.formatMessage({ id: "survey.lang_italian", defaultMessage: "Italian" }) },
    { value: "fi", label: intl.formatMessage({ id: "survey.lang_finnish", defaultMessage: "Finnish" }) },
    { value: "pt", label: intl.formatMessage({ id: "survey.lang_portuguese", defaultMessage: "Portuguese" }) },
    { value: "da", label: intl.formatMessage({ id: "survey.lang_danish", defaultMessage: "Danish" }) },
    { value: "ja", label: intl.formatMessage({ id: "survey.lang_japanese", defaultMessage: "Japanese" }) },
    { value: "th", label: intl.formatMessage({ id: "survey.lang_thai", defaultMessage: "Thai" }) },
    { value: "sv", label: intl.formatMessage({ id: "survey.lang_swedish", defaultMessage: "Swedish" }) },
    { value: "cs", label: intl.formatMessage({ id: "survey.lang_czech", defaultMessage: "Czech" }) },
    // { value: "ta", label: intl.formatMessage({ id: "survey.lang_tamil", defaultMessage: "Tamil" }) },
    // { value: "ml", label: intl.formatMessage({ id: "survey.lang_malayalam", defaultMessage: "Malayalam" }) },
    // { value: "te", label: intl.formatMessage({ id: "survey.lang_telugu", defaultMessage: "Telugu" }) },
    // { value: "mr", label: intl.formatMessage({ id: "survey.lang_marathi", defaultMessage: "Marathi" }) },
    // { value: "kn", label: intl.formatMessage({ id: "survey.lang_kannada", defaultMessage: "Kannada" }) },
    // { value: "gu", label: intl.formatMessage({ id: "survey.lang_gujarati", defaultMessage: "Gujarati" }) },
    // { value: "bn", label: intl.formatMessage({ id: "survey.lang_bengali", defaultMessage: "Bengali" }) },
    // { value: "pa", label: intl.formatMessage({ id: "survey.lang_punjabi", defaultMessage: "Punjabi" }) },
  ];
  return (
    <>
      <Group align="flex-end" spacing={2}>
        <Select
          //label="Language"
          //description="Select the language for buttons. Rest of the content is up to you."
          value={langList.find(l => l.value === currentLang)?.value}
          onChange={lang => handleLangChange(lang)}
          searchable
          nothingFound="Language not available."
          placeholder="Pick one"
          data={langList.sort((a, b) => a.label.localeCompare(b.label))}
          // rightSection={<IconChevronDown size={14} />}
          icon={<IconLanguage size={18} />}
          styles={() => ({
            item: {
              fontSize: "14px",
              fontWeight: 400,
            },
            input: {
              width: "160px",
            },
          })}
        />
        <FSToolTip
          label={intl.formatMessage({
            id: "survey.lang_tooltip",
            defaultMessage: "Select the language for buttons. Rest of the content is up to you.",
          })}
        />
      </Group>
    </>
  );
}
