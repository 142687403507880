import React from "react";
import { Box } from "@mantine/core";
import { Heading } from "../../components";
import { useIntl } from "react-intl";

export default function SurveyCampaign() {
  const intl = useIntl();

  return (
    <Box>
      <Heading title={intl.formatMessage({ id: "survey.campaign.title" })} showDivider />
    </Box>
  );
}
