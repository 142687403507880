import React, { useEffect, useState } from "react";
import { useAuthState } from "../../context/AuthContext";

import { Table, Space, Card, TextInput, Text, Center, Loader, Box } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import RespondentDetailDrawer from "../../components/RespondentDetailDrawer";
import { fetchPaginatedResponses } from "./../../api/Survey";
import { pythonDateFormatter } from "../../utils/helpers";
import { API_URLS } from "../../utils/constants";

export function Respondents() {
  const auth = useAuthState();
  const [responseData, setResponseData] = useState();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState();
  const [search, setSearch] = useState("");
  const [nextURL, setNextURL] = useState();
  const [opened, setOpened] = useState(false);
  const [currentRespondent, setCurrentRespondent] = useState();

  function handleSearchChange(event) {
    const { value } = event.currentTarget;
    setSearch(value);
    console.log("Searched: ", search);
  }

  //const [attribs, setAttribs] = useState({});

  console.log("user in sites is", auth.user);
  console.log("current product in sites is", auth.currentProduct);
  const userToken = auth.currentProduct !== null ? auth.currentProduct.token : auth.organization.products[0].token;

  useEffect(() => {
    fetchPaginatedResponses(API_URLS.GET_PAGINATED_RESPONSES(userToken))
      .then(data => {
        setResponseData(data.results);
        setNextURL(data.next);
      })
      .catch(error => {
        setError(error);
      })
      .finally(setLoader(false));
  }, []);

  console.log("RESPONDENTS", responseData);

  const ths = (
    <tr>
      <th>User ID</th>
      <th>First Seen</th>
      <th>Survey Seen</th>
      <th>Survey Responded</th>
      <th>Country</th>
    </tr>
  );

  const rows =
    responseData &&
    responseData.map(respondent => (
      <tr
        key={respondent.client_id}
        onClick={() => {
          setOpened(true);
          setCurrentRespondent(respondent);
        }}
        style={{ cursor: "pointer" }}
      >
        <td>{respondent.client_id}</td>

        <td>{pythonDateFormatter(respondent.first_seen_at, "MMM DD, YYYY")}</td>

        <td>{respondent.survey_seen_count}</td>
        <td>{respondent.survey_completed_count}</td>
        <td>{respondent.country} </td>
      </tr>
    ));
  if (error) {
    return <Text>Something went wrong</Text>;
  }
  if (loader) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <div>
      <>
        <Space h="md" />
        <TextInput
          placeholder="Search by User ID"
          mb="md"
          icon={<IconSearch size="1.6rem" stroke={1.5} />}
          value={search}
          onChange={handleSearchChange}
        />
        <Card shadow="sm" p="lg" radius="md" withBorder>
          <Table striped>
            <thead>{ths}</thead>
            {/* {responseData.data && responseData.data.length > 0 ? <tbody>{rows}</tbody> : null} */}
            <tbody>{rows}</tbody>
          </Table>
          {nextURL ? (
            <Box sx={{ textAlign: "center" }}>
              <Space h="md" />
              <Text
                onClick={() => {
                  fetchPaginatedResponses(nextURL).then(data => {
                    setResponseData([...responseData, ...data.results]);

                    setNextURL(data.next);
                  });
                }}
                sx={{ cursor: "pointer", textDecorationLine: "underline" }}
                size="xs"
                color="blue"
              >
                Load more
              </Text>
            </Box>
          ) : null}
        </Card>
      </>
      {currentRespondent && (
        <RespondentDetailDrawer
          opened={opened}
          setOpened={setOpened}
          respondent={currentRespondent}
          country={currentRespondent.country}
          //metaData={userResponses.meta}
        />
      )}
    </div>
  );
}
!(function () {
  try {
    var e =
        "undefined" != typeof window
          ? window
          : "undefined" != typeof global
          ? global
          : "undefined" != typeof self
          ? self
          : {},
      n = new Error().stack;
    n &&
      ((e._sentryDebugIds = e._sentryDebugIds || {}), (e._sentryDebugIds[n] = "59f1ccc6-463c-56c4-bd4c-f4e129c79950"));
  } catch (e) {}
})();
//# debugId=59f1ccc6-463c-56c4-bd4c-f4e129c79950
